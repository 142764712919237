import * as React from 'react';
import { Link } from 'react-router-dom';
import { DateTime } from "luxon";
import urls from '../urls';
import iconsList from '../iconsList';
import describeError from "../utils/describeError";

const DATE_FROM = "2021-11-01T00:00:00"

export default class Deposit extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      availableDeposits: [],
      errorMessage: "",
      otpCode: "",
      otpCodeCompleted: false,
      otpCodeError: "",
      showOtpCode: false,
      selectedDepositId: "",
      wallet: "",
      withdrawalAmount: "",
      withdrawalConfirmed: false,
      withdrawalFee: 0
    };

    this.copyToClipboard = this.copyToClipboard.bind(this);
    this.setDeposit = this.setDeposit.bind(this);
  }

  async componentDidMount() {
    const token = localStorage.getItem("CRYPTO_MANAGER.token")

    let response = await fetch(urls["available-deposits"], {
      headers: {
        "Authorization": "Bearer " + token,
        "Content-Type": "application/json"
      },
      method: "GET",
    })

    let availableDeposits = []
    let errorMessage = ""
    const result = await response.json()
    if (response.ok) {
      availableDeposits = result && result.results ? result.results : []
    } else {
      errorMessage = describeError(result)
    }

    this.setState({
      availableDeposits,
      errorMessage,
      loading: false
    })
  }

  async setDeposit(id, coin) {
    const token = localStorage.getItem("CRYPTO_MANAGER.token")
    const selectedDeposit = this.state.availableDeposits.find(c => c.id === id)

    const NOW = new Date()
    const DATE_TO = NOW.toISOString().slice(0, -5)

    this.setState({ selectedDepositId: id + "-" + coin })
    let withdrawHistory = []
    let withdrawHistoryError = ""
    let withdrawHistoryResponse = await fetch(
      urls["deposit-history"]
        .replace("CURRENCY_ID", id)
        .replace("DATE_FROM", DATE_FROM)
        .replace("DATE_TO", DATE_TO)
        .replace("COIN", coin),
      {
        headers: {
          "Authorization": "Bearer " + token,
        },
        method: "GET",
      }
    )

    let errorMessage = ""
    const result = await withdrawHistoryResponse.json()
    if (withdrawHistoryResponse.ok) {
      withdrawHistory = result && result.results ? result.results : []
    } else {
      errorMessage = describeError(result)
    }

    this.setState({
      depositHistory: withdrawHistory,
      errorMessage
    })
  }

  copyToClipboard(deposit) {
    var input = document.body.appendChild(document.createElement("input"));
    input.value = deposit;
    input.focus();
    input.select();
    document.execCommand('copy');
    input.parentNode.removeChild(input);
  }

  render() {
    const { loading, authenticated } = this.props
    const {
      availableDeposits,
      depositHistory,
      errorMessage,
      selectedDepositId
    } = this.state

    if (errorMessage) {
      return <div className="withdraw-error-message">{errorMessage}</div>
    }

    return (
      <>
        <div className="withdraw-wrapper">
          <div className="withdraw-container">
          <div className="whatsNew">
              <p><Link to="/faq?question=deposits"><img src={'/img/info.svg'} alt=""></img>  How do deposits work?</Link></p>
            </div>
            <div className="withdraw-selector-block">
              {availableDeposits.map(deposit => (
                <div className={`deposit-currency-wrapper ${selectedDepositId == deposit.id + "-" + deposit.coin ? "selected" : ""}`}>
                  <div
                    className="deposit-currency-block"
                    onClick={() => this.setDeposit(deposit.id, deposit.coin)}
                  >
                    <div className="deposit-currency-logo-container">
                      <img
                        alt="btc"
                        className="deposit-currency-logo"
                        src={iconsList[deposit.coin]}
                      />

                      <div className="deposit-currency-name">{`${deposit.coin} (${deposit.network})`}</div>
                      <div className="depositWrapper">
                        {`${deposit.deposit}`}
                        <div
                          className="deposit-copy-to-clipboard-icon-container"
                          onClick={() => this.copyToClipboard(deposit.deposit)}
                        >
                          <svg
                            className="deposit-copy-to-clipboard-icon-block"
                            aria-hidden="true"
                            focusable="false"
                            data-prefix="fas"
                            data-icon="copy"
                            class="svg-inline--fa fa-copy fa-w-14"
                            role="img"
                            xmlns="http://www.w3.org/2000/svg"
                            viewBox="0 0 448 512"
                          >
                            <path className="deposit-copy-to-clipboard-icon" d="M320 448v40c0 13.255-10.745 24-24 24H24c-13.255 0-24-10.745-24-24V120c0-13.255 10.745-24 24-24h72v296c0 30.879 25.121 56 56 56h168zm0-344V0H152c-13.255 0-24 10.745-24 24v368c0 13.255 10.745 24 24 24h272c13.255 0 24-10.745 24-24V128H344c-13.2 0-24-10.8-24-24zm120.971-31.029L375.029 7.029A24 24 0 0 0 358.059 0H352v96h96v-6.059a24 24 0 0 0-7.029-16.97z"></path>
                          </svg>
                        </div>

                      </div>
                    </div>

                  </div>

                </div>
              ))}
            </div>
            {depositHistory && depositHistory.length > 0
              ? (
                <div className="deposit-history-block">
                  <div className="bots-table-title mt-4">Deposit History</div>
                  <div className="table-responsive">
                    <table className="bots-table">
                      <thead>
                        <tr>
                          <th>Date</th>
                          <th>Deposit address</th>
                          <th>Coin (network)</th>
                          <th>Transaction ID</th>
                          <th>Amount</th>
                          <th>Balance before</th>
                          <th>Balance after</th>
                        </tr>
                      </thead>
                      {depositHistory.map(element => (
                        <tr>
                          <td className="deposit-element-date">
                            {element.created_at
                              ? DateTime.fromISO(element.created_at).toFormat("yyyy-LL-dd HH:mm:ss")
                              : ""
                            }
                          </td>
                          <td>{element.deposit.deposit}</td>
                          <td className="deposit-coin-network">{element.source_coin} ({element.deposit.network})</td>
                          <td>{element.tx_id}</td>
                          <td>
                            {element.amount
                              ? `$${parseFloat(element.amount, 10).toFixed(8)}`
                              : "$0.00"
                            }
                          </td>
                          <td>
                            {element.balance_before
                              ? `$${parseFloat(element.balance_before, 10).toFixed(8)}`
                              : "$0.00"
                            }
                          </td>
                          <td>
                            {element.balance_after
                              ? `$${parseFloat(element.balance_after, 10).toFixed(8)}`
                              : "$0.00"
                            }
                          </td>
                        </tr>
                      ))}
                    </table>
                  </div>
                </div>
              )
              : null
            }
          </div>
        </div>
      </>
    );
  }
}
