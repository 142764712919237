import React from 'react';
import { Accordion } from "react-bootstrap";

export default class Dashboard extends React.Component {
  
  myFun = () => {
    const url = new URL(window.location.href);
    const question = url.searchParams.get('question');
    var number = ""
       
    if (question === "risks") {
      number = "7"
    }
    if (question === "deposits") {
      number = "8"
    }
    if (question === "withdrawal") {
      number = "11"
    }
    if (question === "api-keys") {
      number = "12"
    }
    if (question === "2fa") {
      number = "13"
    }
    if (question === "close-positions") {
      number = "14"
    }

    window.scrollTo({ left: 0, top: document.body.scrollHeight, behavior: "smooth" });

    return number
  }

  render() {

    return (
      <div className="faq-container mb-5 pb-5" >
        <Accordion defaultActiveKey={this.myFun()}>

          <Accordion.Toggle eventKey="0">
            <div className="faq-title">WHAT IS IT TAILORED FOR? <img src="./img/arrowDown.svg" alt=""></img></div>
          </Accordion.Toggle>

          <Accordion.Collapse eventKey="0">
            <div className="faq-text">
              It is created for passive investors, who want to earn extra on their cryptocurrencies held on supported exchanges.<br /><br />
              Minimum recommended testing period is 6 months, without moving the starting capital. This is not a get rich quick bot, which earns impressive profits and blows up (gets margin call and loses everything) the account every 3 months.<br /><br />
              While losing everything in the account is still a possibility, this is a much more stable and reliable trading system than current cryptocurrency market offers.<br /><br />
              It has been used for managing large capital ($1 million+) everyday starting 2020 February.<br /><br /> 
            </div>
          </Accordion.Collapse>

          <Accordion.Toggle eventKey="1">
            <div className="faq-title">WHAT PROFITS CAN BE EXPECTED? <img src="./img/arrowDown.svg" alt=""></img></div>
          </Accordion.Toggle>

          <Accordion.Collapse eventKey="1">
            <div className="faq-text">
              <span>Profits highly vary based on market conditions. An average of <span className="green"><b>3&#37;/month</b></span> can be expected, a good month can generate <span className="green"><b>10&#37; profit</b></span> and a bad month can generate about <span className="red"><b>10&#37; loss</b></span>. Please check our <a target='_blank' href='https://www.cryptomanager.ai/advanced-fund-performance-details'>financial tearsheets</a> for more detailed information.
                Crypto Manager will be most profitable in highly volatile market conditions, when market participants become emotional. It earns best in a sideways market or in a market in any direction, which has regular corrections. Bitcoin and Ethereum proved to provide great market conditions for past several years.<br />
                <iframe width="560" height="315" src="https://www.youtube.com/embed/fQW2sQ_fcCQ" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
                <br /><br /></span>
            </div>
          </Accordion.Collapse>




          <Accordion.Toggle eventKey="2">
            <div className="faq-title">WHY ARE WE SHARING THIS? <img src="./img/arrowDown.svg" alt=""></img></div>
          </Accordion.Toggle>

          <Accordion.Collapse eventKey="2">
            <div className="faq-text">
              We charge 20% success fee from the profits generated by Crypto Manager. Offering this service to the public allows us to increase our capital, while keeping our strategy proprietary.<br /><br />
            </div>
          </Accordion.Collapse>


          <Accordion.Toggle eventKey="3">
            <div className="faq-title">HOW ARE THE TRADING PROFITS SHARED? <img src="./img/arrowDown.svg" alt=""></img></div>
          </Accordion.Toggle>

          <Accordion.Collapse eventKey="3">
            <div className="faq-text">
              <span>When you register an account, you have a balance of &#36;20, which let&#39;s Crypto Manager to earn &#36;100 for you on your cryptocurrency exchange account. If the profit goes over this amount even for a short period of time, Crypto Manager stops trading and charges your balance for the 20&#37; profit share.<br /><br />
                When you decide that you want to use Crypto Manager long term, you have to prefund the account with 20&#37; of potential monthly profits. For example if you are putting &#36;10.000 for management, expected profit could be between &#36;0 up to &#36;1000, so you will need roughly &#36;250 in your Crypto Manager wallet. It is better to have a reserve of Crypto Manager balance, because profits might fluctuate and every time bot is stopped, new launch costs &#36;5. We keep a history of all Crypto Manager trades and calculate the profit when Crypto Manager is stopped and charge our 20&#37; share.<br /><br />
                You are free to withdraw your Crypto Manager balance at any time. Withdrawals are processed once a week on Mondays.<br /><br /></span>
            </div>
          </Accordion.Collapse>


          <Accordion.Toggle eventKey="4">
            <div className="faq-title">WHAT DO I NEED TO DO TO START TRADING? <img src="./img/arrowDown.svg" alt=""></img></div>

          </Accordion.Toggle>

          <Accordion.Collapse eventKey="4">

            <div className="faq-text">
              <span>
                First, you will need to generate api keys with trading permissions on one of the supported exchanges. Please find <a className="green link" href="/faq?question=api-keys">instructions here</a>. We highly recommend to always use a sub account or a dedicated exchange account for Crypto Manager so the capital is not mixed with other trading activities and will not suddenly decrease or increase.<br /><br />
                Once you have deposited the desired amount for management to your exchange of either BTC, ETH or USDT and saved your api keys, you can pre-fund your Crypto Manager account for potential profit share by clicking on DEPOSIT. When all balances are settled, you can click on &#34;START&#34; button on your saved bot. Every time you click on start Crypto Manager, you are charged &#36;5, because in the backend a dedicated virtual private server is being deployed to run crypto manager for you.<br /><br />
                Crypto Manager will query your margin balance and based on that, set limits for trading. So if you have BTC worth &#36;10.000, it will short maximum &#36;10.000 worth of BTC and go long maximum &#36;10.000 worth of BTC. It will not go over these position limits, unless the capital changes or the price of the underlying asset changes. After that it will analyze the market and send a grid of limit buy/sell orders and keep repeating the process every hour. 
                <br /><br />
                </span>
            </div>
          </Accordion.Collapse>


          <Accordion.Toggle eventKey="5">
            <div className="faq-title">HOW TO STOP TRADING? <img src="./img/arrowDown.svg" alt=""></img></div>
          </Accordion.Toggle>

          <Accordion.Collapse eventKey="5">

            <div className="faq-text">
              <span>
                If you decide that you need part of the managed capital or want to stop trading completely. You will need to click on &#34;STOP&#34; button next to your bot in the dashboard. Once the button is clicked, any open orders left on the exchange will be cancelled and no more orders will be sent.<br /><br />
                Based on the trades that Crypto Manager has done so far it will calculate the profit that has been generated. Usually you will still have an open long or short position. Crypto Manager will assume it is being market closed and calculate profit based on current market price.  However the position that Crypto Manager has accumulated will not be touched and you will have to close it manually or keep it. Click on the <a href="/faq?question=close-positions">link here</a> to find a video tutorial how you can stop everything.<br />
                If you plan to withdraw funds from margin account, we recommend closing any margin positions, because decreasing capital may cause sudden liquidation.  
              <br /><br />
              </span>
            </div>
          </Accordion.Collapse>


          <Accordion.Toggle eventKey="6">
            <div className="faq-title">WHAT IS THE EXACT STRATEGY BEHIND THIS? <img src="./img/arrowDown.svg" alt=""></img></div>

          </Accordion.Toggle>

          <Accordion.Collapse eventKey="6">

            <div className="faq-text">
              <span>
                Every hour Crypto Manager analyzes the market conditions it is trading on and based on the results it sends a grid of limit buy and sell orders.  This is called Market Making and it basically delivers more liquidity to the market it is trading on. Sometimes the orders get filled, and sometimes they get cancelled and replaced by new orders after an hour.  Strategy doesn&#39;t get old, because it adapts to each day&#39;s market conditions.<br /><br />
                Eventually it slowly accumulates a long or short position, which usually has a good entry price.  As time passes by Crypto Manager slowly capitalizes on emotional market moves. It is best to test Crypto Manager with a small balance to see it running live and get a feeling how it works.
              <br /><br />
              </span>
            </div>
          </Accordion.Collapse>

        <Accordion.Toggle eventKey="7">
            <div className="faq-title">WHAT ARE THE RISKS WHILE USING CRYPTO MANAGER? <img src="./img/arrowDown.svg" alt=""></img></div>

          </Accordion.Toggle>

          <Accordion.Collapse eventKey="7">

            <div className="faq-text">
              <span>
                While we have been using Crypto Manager with our own funds successfully for more than 2 years, it does not mean there is no risk. We do not take any responsibility for any losses incurred by using Crypto Manager, even if it would be our code fault. While we try to ensure maximum safety by keeping api keys safe, sending only post_only orders to avoid getting liquidated and never using leverage above 2x it still has risks. If you are using BTC as collateral and price starts dropping sharply used leverage may become more than 2x, if price drops more than 50% your account will be liquidated, due to too low collateral. If an exchange provides incorrect price data Crypto Manager may send orders with incorrect prices or if an exchange provides incorrect balance, Crypto Manager may go over the 2x leverage and get liquidated. In the future we plan to create an insurance fund to cover the mentioned risks, but at the moment WE DO NOT GUARANTEE ANYTHING, YOU MAY LOSE ALL YOUR FUNDS AND WE WILL NOT COVER ANY LOSSES.
              <br /><br />
              </span>
            </div>
          </Accordion.Collapse>

          


          <Accordion.Toggle eventKey="8">
            <div className="faq-title">HOW DO DEPOSITS WORK? <img src="./img/arrowDown.svg" alt=""></img></div>

          </Accordion.Toggle>

          <Accordion.Collapse eventKey="8">

            <div className="faq-text">
              <span>
                <p>When you go to deposit section and deposit BTC, ETH or USDT all of these currencies are converted to Crypto Manager USD balance. Bitcoin and Ethereum are sold at market price at that moment, which is taken from coinmarketcap.com api. </p>
                <p>1 USD at Crypto Manager is equal to 1 USDT.</p>
                <p> If you deposit BTC or ETH and decide to withdraw your balance later, you will be able to withdraw USDT, since all deposits are immediately converted to USD and you do not hold BTC or ETH.</p>
              <br /><br />
              </span>
            </div>
          </Accordion.Collapse>

          <Accordion.Toggle eventKey="9">
            <div className="faq-title">HOW ARE THE PROFITS CALCULATED AND CHARGED? <img src="./img/arrowDown.svg" alt=""></img></div>

          </Accordion.Toggle>

          <Accordion.Collapse eventKey="9">

            <div className="faq-text">
              <span>
                <p>Every time Crypto Manager sends an order, it logs order id and later checks if that order was filled or cancelled. </p>
                <p>Profits are strictly calculated based on executed orders or if you are stopping the bot additionally your current profit from open position is included.</p>
                <p>If you are managing your Bitcoin or Ethereum with Crypto Manager and their market price starts to increase you will not be charged for profit increase, no matter what is their price. We will charge you only when we earn additional cryptocurrency for you.</p>
                <p>If your trading asset is Bitcoin and we earn you 10&#37;, we will charge 2&#37; from the earnings and it does not matter how much Bitcoin price increases or decreases. </p>
                <p>Profits are charged in 2 scenarios:</p>
                <ul>
                <li>1) Bot is stopped by yourself</li>
                <li>2) Bot is stopped by Crypto Manager, because your balance is lower than 20&#37; of the profit</li>
                </ul>
              <br /><br />
              </span>
            </div>
          </Accordion.Collapse>

          <Accordion.Toggle eventKey="10">
            <div className="faq-title">BESIDES PROFIT SHARE WHAT OTHER FEES DO I HAVE TO PAY? <img src="./img/arrowDown.svg" alt=""></img></div>

          </Accordion.Toggle>

          <Accordion.Collapse eventKey="10">

            <div className="faq-text">
              <span>
              <p>Below you can find a complete set of fees:</p>
                <ul>
              <li>1) <b>&#36;5</b> bot launch fee - every time you start a bot.</li>
              <li>2) Withdrawal fee for withdrawing USDT. <b>&#36;1</b> on TRC-20 network and <b>&#36;35</b> on ERC-20 network.</li>
              <li>3) <b>20&#37;</b> profit fee, when your bot is stopped.</li>
              </ul>
              <br /><br />
              </span>
            </div>
          </Accordion.Collapse>


          <Accordion.Toggle eventKey="11">
            <div className="faq-title">HOW LONG UNTIL MY WITHDRAWAL IS PROCESSED? <img src="./img/arrowDown.svg" alt=""></img></div>

          </Accordion.Toggle>

          <Accordion.Collapse eventKey="11">

            <div className="faq-text">
              <span>
              <p>We process Crypto Manager USDT withdrawals once per week. It is done on Mondays at 12:00 UTC, if you have requested for withdrawal after this time, you will have to wait until next Monday.</p>
              <br /><br />
              </span>
            </div>
          </Accordion.Collapse>


          <Accordion.Toggle eventKey="12" ref={this.myRef}>
            <div className="faq-title">WHAT ARE API KEYS AND HOW TO GENERATE THEM? <img src="./img/arrowDown.svg" alt=""></img></div>

          </Accordion.Toggle>

          <Accordion.Collapse eventKey="12">

            <div className="faq-text">
            <span>
              In order to generate api keys, you will need to register an account on Bitmex or Deribit.<br />
              <div className="d-flex flex-row">  Register on <a href="https://www.bitmex.com/register/2powaz" rel="noreferrer" target="_blank" className="ml-2"><strong>BitMEX</strong></a><br /></div>
              <div className="d-flex flex-row"> Register on <a href="https://www.deribit.com/?reg=16360.9101" rel="noreferrer" target="_blank" className="ml-2"><strong>Deribit</strong></a><br /></div>

              Once you have a verified account follow these video instructions to generate api keys:<br /><br />

              Our server ip for whitelisting: <b>146.59.92.158</b> <br /><br />          
              Bitmex<br />
              <iframe width="560" height="315" src="https://www.youtube.com/embed/KxDYkWNDY74" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
              <br /><br />
              Deribit<br />
              <iframe width="560" height="315" src="https://www.youtube.com/embed/mZLb6k9m5MQ" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
            <br /><br />
            </span>
            </div>
          </Accordion.Collapse>

          <Accordion.Toggle eventKey="13" ref={this.myRef}>
            <div className="faq-title">WHAT IS 2FA AUTHENTICATOR AND HOW TO SET IT UP? <img src="./img/arrowDown.svg" alt=""></img></div>

          </Accordion.Toggle>

          <Accordion.Collapse eventKey="13">

            <div className="faq-text">
            <span>
              <p>Two-factor authentication (2FA) is an additional layer of security for your Crypto Manager Account. With 2FA enabled, you will be prompted to enter a 6-digit code when you log in to your account. This 6-digit code will be generated by an app installed on your mobile phone.</p>

              <p>This means that even if your password is somehow stolen, an attacker still cannot get into your account without also having access to your mobile phone. Because of this security benefit, we recommend enabling 2FA on your account.</p>

              <p>To use 2FA, you must first install an authenticator app on your mobile phone and have access to your phone while logging in to your account. There are many authenticator apps to choose from. Below are a few options, but this is by no means a definitive list:
              </p>

              <p><strong>Android</strong></p>

              <ul>
              <li><a rel="noreferrer noopener" target="_blank" href="https://www.authy.com/">Authy</a></li>
              <li><a rel="noreferrer noopener" target="_blank" href="https://play.google.com/store/apps/details?id=com.google.android.apps.authenticator2&amp;hl=en">Google Authenticator</a></li>
              <li><a rel="noreferrer noopener" target="_blank" href="https://play.google.com/store/apps/details?id=org.fedorahosted.freeotp">FreeOTP</a></li>
              <li><a rel="noreferrer noopener" target="_blank" href="https://play.google.com/store/apps/details?id=org.shadowice.flocke.andotp">andOTP</a> (also available from F-Droid)</li>
              </ul>
              <br />

              <p><strong>iOS&nbsp; and iPadOS</strong></p>

              <ul>
              <li><a rel="noreferrer noopener" target="_blank" href="https://www.authy.com/">Authy</a></li>
              <li><a rel="noreferrer noopener" target="_blank" href="https://itunes.apple.com/us/app/google-authenticator/id388497605?mt=8">Google Authenticator</a></li>
              <li><a rel="noreferrer noopener" target="_blank" href="https://itunes.apple.com/us/app/freeotp-authenticator/id872559395?mt=8">FreeOTP</a></li>
              </ul>

            <br /><br />
            </span>
            </div>
          </Accordion.Collapse>

   <Accordion.Toggle eventKey="14" ref={this.myRef}>
            <div className="faq-title">HOW TO CLOSE OPEN POSITIONS? <img src="./img/arrowDown.svg" alt=""></img></div>

          </Accordion.Toggle>

          <Accordion.Collapse eventKey="14">

            <div className="faq-text">
            <span>
              Bitmex instructions:<br />
              <iframe width="560" height="315" src="https://www.youtube.com/embed/BquDeZjJ9Ew" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
              <br /><br />
              Deribit instructions:<br />
              <iframe width="560" height="315" src="https://www.youtube.com/embed/3H12D2gAw2A" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
            <br /><br />
            </span>
            </div>
          </Accordion.Collapse>

  <Accordion.Toggle eventKey="15" ref={this.myRef}>
            <div className="faq-title">WHAT IS THE RECOMMENDED MINIMUM CAPITAL TO START? <img src="./img/arrowDown.svg" alt=""></img></div>

          </Accordion.Toggle>

          <Accordion.Collapse eventKey="15">

            <div className="faq-text">
            <span>
              For Deribit we recommend &#36;2000 and for Bitmex we recommend &#36;20.000.<br />
              Below you can find a more detailed video running a simulation with different capital sizes and explaining why having enough capital is crucial for good performance of the strategy.<br / >
              <iframe width="560" height="315" src="https://www.youtube.com/embed/1x5nv0evC9I" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
              <br /><br />
            </span>
            </div>
          </Accordion.Collapse>



        </Accordion>


      </div>
    )
  }
}
