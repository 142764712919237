import React from 'react';
import { Redirect, Link } from 'react-router-dom';
import urls from '../../urls';

export default class SignIn extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      email: "",
      error: "",
      loading: false,
      loggedIn: false,
      password: "",
    };

    this.signIn = this.signIn.bind(this);
  }

  async signIn(e) {
    e.preventDefault();
    e.stopPropagation();

    if (this.state.loading) return

    this.setState({
      error: "",
      loading: true
    })

    let signInData = {
      email: this.state.email,
      password: this.state.password,
    }

    const token = localStorage.getItem("CRYPTO_MANAGER.token")
    let response = await fetch(urls["auth"], {
      method: "POST",
      headers: {
        "Authorization": "Bearer " + token,
        "Content-Type": "application/json"
      },
      body: JSON.stringify(signInData)
    })

    if (response.ok) {
      const { token } = await response.json()
      
      localStorage.removeItem("CRYPTO_MANAGER.token")
      localStorage.setItem("CRYPTO_MANAGER.token", token)

      this.setState({
        loggedIn: true
      })
    } else {
      this.setState({
        error: "Invalid email or password"
      })
    }
    this.setState({
      loading: false
    })

    this.props.checkLogin()
  }

  render() {
    const { loggedIn } = this.state

    if (loggedIn) {
      return <Redirect to='/profile'/>;
    }

    return (
      <>
        <div className="login-container vh-100 d-flex">
          <div className="form-access my-auto block">
            <form onSubmit={this.signIn}>
              <span>SIGN IN</span>
              <h2 className="dont-have-account top">
                Don't have an account? <Link to="/signup">Sign up here</Link>
              </h2>
              <div className="form-group">
                <input
                  autoFocus
                  type="email"
                  className="form-control"
                  onChange={(e) => this.setState({ email: e.target.value })}
                  placeholder="EMAIL ADDRESS"
                  value={this.state.email}
                  required
                />
              </div>
              <div className="form-group">
                <input
                  type="password"
                  className="form-control"
                  onChange={(e) => this.setState({ password: e.target.value })}
                  placeholder="PASSWORD"
                  value={this.state.password}
                  required
                />
              </div>
              <div className="text-right">
                <Link to="/reset">Forgot Password?</Link>
              </div>
              {this.state.error
                ? <div className="error">Something went wrong</div>
                : null
              }
              {this.state.loading
                ? <button className="login-submit-btn btn btn-primary loading">SIGN IN</button>
                : <button type="submit" className="login-submit-btn btn btn-primary">SIGN IN</button>
              }
            </form>
            <h2 className="dont-have-account bottom">
              Don't have an account? <Link to="/signup">Sign up here</Link>
            </h2>
          </div>
          <div className="brain-container block">
            <img className="brain" src={'img/landing/brain_v2.svg'} alt="graph" />
          </div>
        </div>
      </>
    );
  }
}
