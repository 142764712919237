import * as React from 'react';
import { Link } from 'react-router-dom';
import SimpleButton from '../../components/SimpleButton';
import urls from '../../urls';
import describeError from "../../utils/describeError";
import styled from "styled-components";

const StyledLink = styled(Link)`
  margin-top: 50px;
`;

const ConfirmContainer = styled.div`
  align-items: center;
  color: #fff;
  display: flex;
  min-height: calc(100vh - 73px);
  justify-content: center;
  margin-top: 73px;
  padding: 20px;
  @media (min-width: 600px) {
    min-height: calc(100vh - 104px);
    margin-top: 104px;
  }
  @media (min-width: 1200px) {
    min-height: calc(100vh - 149px);
    margin-top: 149px;
  }
`

const ConfirmDescription = styled.div`
  font-family: 'Roboto Condensed', sans-serif;
  font-size: 2rem;
  text-align: center;
`

export default class ConfirmAccount extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      confirmedAccount: false,
      loading: true,
      token: "",
    };

    this.confirmAccount = this.confirmAccount.bind(this)
  }

  async componentDidMount() {
    this.confirmAccount()
  }

  async confirmAccount() {
    const token = window && window.location && window.location.pathname
      ? window.location.pathname.replace("/confirm-account/", "")
      : ""

    this.setState({
      error: ""
    })

    let response = await fetch(urls["confirm-account"], {
      method: "POST",
      headers: {
        "Content-Type": "application/json"
      },
      body: JSON.stringify({
        token
      })
    })

    let errorMessage = ""
    const result = await response.json()
    if (response.ok) {
      this.setState({ confirmedAccount: true })
    } else {
      errorMessage = describeError(result)
    }

    this.setState({
      error: errorMessage,
      loading: false
    })
  }

  render() {
    const {
      confirmedAccount,
      error,
      loading
    } = this.state

    if (loading) {
      return (
        <ConfirmContainer>
          <div className="confirm-account-successfull">Please wait</div>
        </ConfirmContainer>
      )
    }

    if (confirmedAccount) {
      return (
        <div className="confirm-account-container">
          <div className="confirm-account-successfull">Your email has been approved and account created.<br />You may sign in now.</div>
          <div>
            <Link to="/login" className="btn btn-primary mt-3">SIGN IN</Link>
          </div>
        </div>
      )
    } else {
      return (
        <>
          <ConfirmContainer>
            {this.state.error
              ? <div className="error">{error}</div>
              : null
            }
          </ConfirmContainer>
        </>
      )
    }
  }
}