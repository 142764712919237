const describeError = errorObject => {
  const errorFieldName = Object.keys(errorObject) && Object.keys(errorObject).length > 0
    ? Object.keys(errorObject)[0]
    : undefined
  return errorFieldName
    ? Array.isArray(errorObject[errorFieldName])
      ? errorObject[errorFieldName][0]
      : errorObject[errorFieldName]
    : "Unknown error"
}

export default describeError
