import React from 'react';
import { Link } from 'react-router-dom';

export default function notfound() {
  return (
    <>
      <div className="notfound-container">
        <div className="notfound-background-container">
          <div className="notfound-background-wrapper">
            <img className="notfound-background-left" src={'img/landing/hero_background_left.svg'} alt="graph" />
            <img className="notfound-background-right" src={'img/landing/hero_background_right.svg'} alt="graph" />
            <img className="notfound-background" src={'img/notfound_background.png'} alt="graph" />
          </div>
        </div>
        <div className="notfound-image-container">
          <img className="notfound-image" src={'img/404.svg'} alt="graph" />
          <div className="notfound-title">Oops... Page not found...</div>
          <div className="notfound-description">Please, return to the homepage. The problem will be taken care of shortly.</div>
          <a className="notfound-homepage-btn" href="/">TO THE HOMEPAGE</a>
        </div>
      </div>
    </>
  );
}
