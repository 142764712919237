import * as React from 'react';
import { DateTime } from "luxon";
import { Link } from 'react-router-dom';
import urls from '../urls';
import iconsList from '../iconsList';
import describeError from "../utils/describeError";
import Tooltip from '@mui/material/Tooltip';

const DATE_FROM = "2021-11-01T00:00:00"
const NOW = new Date()
const DATE_TO = NOW.toISOString().slice(0, -5)

export default class Wallet extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      availableCoins: [],
      error: "",
      errorMessage: "",
      otpCode: "",
      otpCodeCompleted: false,
      otpCodeError: "",
      showOtpCode: !this.props.onetimeWithdraw,
      selectedCurrencyId: "",
      wallet: "",
      withdrawalAmount: "",
      withdrawalConfirmed: false,
      withdrawalFee: 0
    };

    this.confirmAuth = this.confirmAuth.bind(this);
    this.confirmOtpCode = this.confirmOtpCode.bind(this);
    this.setCurrency = this.setCurrency.bind(this);
    this.setWithdrawalAmount = this.setWithdrawalAmount.bind(this);
    this.withdrawMoney = this.withdrawMoney.bind(this);
  }

  async componentDidMount() {
    const token = localStorage.getItem("CRYPTO_MANAGER.token")

    let response = await fetch(urls["available-coin"], {
      headers: {
        "Authorization": "Bearer " + token,
        "Content-Type": "application/json"
      },
      method: "GET",
    })

    let availableCoins = []
    let errorMessage = ""

    const result = await response.json()
    if (response.ok) {
      availableCoins = result && result.results ? result.results : []
    } else {
      errorMessage = describeError(result)
      this.setState({ errorMessage })
      return
    }

    availableCoins = availableCoins.map(availableCoin => ({
      id: availableCoin.id,
      coin: availableCoin.coin,
      imageUrl: iconsList[availableCoin.coin],
      network: availableCoin.network,
      withdrawal_fee: parseFloat(availableCoin.withdrawal_fee, 10)
    }))

    let withdrawHistory = []
    let withdrawHistoryError = ""
    let withdrawHistoryJsonResponse = await fetch(
      urls["transaction-history"]
        .replace("DATE_FROM", DATE_FROM)
        .replace("DATE_TO", DATE_TO),
      {
        headers: {
          "Authorization": "Bearer " + token,
        },
        method: "GET",
      }
    )

    errorMessage = ""
    const withdrawHistoryResponse = await withdrawHistoryJsonResponse.json()
    if (withdrawHistoryJsonResponse.ok) {
      withdrawHistory = withdrawHistoryResponse && withdrawHistoryResponse.results
        ? withdrawHistoryResponse.results
        : []
    } else {
      errorMessage = describeError(withdrawHistoryResponse)
    }

    this.setState({
      availableCoins,
      errorMessage,
      loading: false,
      withdrawHistory
    })
  }

  async setCurrency(id) {
    const token = localStorage.getItem("CRYPTO_MANAGER.token")
    let coinError = ""

    let response = await fetch(urls["available-4-withdrawal"] + id, {
      headers: {
        "Authorization": "Bearer " + token,
        "Content-Type": "application/json"
      },
      method: "GET",
    })

    let availableForWithdrawal = 0
    const result = await response.json()
    if (response.ok) {
      availableForWithdrawal = result && result.available_balance
        ? result.available_balance
        : 0
    } else {
      coinError = describeError(result)
    }

    const selectedCoin = this.state.availableCoins.find(c => c.id === id)

    this.setState({
      availableForWithdrawal,
      coinError,
      selectedCurrencyId: id,
      withdrawalFee: selectedCoin.withdrawal_fee
    })
  }

  setWithdrawalAmount(e) {
    const {
      availableForWithdrawal,
      withdrawalFee
    } = this.state

    let desiredAmount = e.target.value
      ? parseFloat(e.target.value, 10)
      : 0

    if (desiredAmount > availableForWithdrawal) {
      desiredAmount = availableForWithdrawal
    }

    let withdrawalAmount = 0
    let withdrawalWarning = ""
    if (desiredAmount <= withdrawalFee) {
      withdrawalWarning = `Amount should be greater than transaction fee ($${withdrawalFee})`
    } else {
      withdrawalAmount = desiredAmount - withdrawalFee
    }

    this.setState({
      desiredAmount,
      withdrawalAmount,
      withdrawalWarning
    })
  }

  async withdrawMoney() {
    const token = localStorage.getItem("CRYPTO_MANAGER.token")

    this.setState({ otpCodeError: "" })

    let response = await fetch(urls["checklogin"], {
      headers: {
        "Authorization": "Bearer " + token
      },
      method: "GET",
    })

    if (response.ok) {
      const { onetime_withdraw } = await response.json()
      if (!onetime_withdraw) {
        this.setState({
          showOtpCode: true,
          otpCodeError: ""
        })
      }
    } else {
      this.setState({
        otpCodeError: "You should confirm your account with OTP code"
      })
      return
    }

    let withdrawData = {
      amount: this.state.withdrawalAmount,
      address: this.state.wallet,
      coin: this.state.selectedCurrencyId,
    }

    let withdrawResponse = await fetch(urls["withdraw"], {
      method: "POST",
      headers: {
        "Authorization": "Bearer " + token,
        "Content-Type": "application/json"
      },
      body: JSON.stringify(withdrawData)
    })

    const result = await withdrawResponse.json()
    if (withdrawResponse.ok) {
      this.setState({
        withdrawalConfirmed: result && result.detail
      })
    } else {
      const withdrawErrorMessage = describeError(result)
      this.setState({
        withdrawErrorMessage
      })
    }
  }

  async confirmAuth(otpCode) {
    const token = localStorage.getItem("CRYPTO_MANAGER.token")

    if (!otpCode) {
      otpCode = this.state.otpCode
    }

    let confirmAuthResponse = await fetch(urls["totp-withdraw"] + otpCode + "/", {
      headers: {
        "Authorization": "Bearer " + token
      },
      method: "GET",
    })
    if (confirmAuthResponse.ok) {
      this.setState({
        otpCodeCompleted: true,
        otpCodeError: "",
        showOtpCode: false
      })
    } else {
      this.setState({
        otpCodeError: "OTP code is incorrect",
      })
    }
  }

  async confirmOtpCode(e) {
    this.setState({ otpCode: e.target.value })

    if (e.target.value.length === 6) {
      setTimeout(async () => {
        this.confirmAuth(e.target.value)
      }, 1000)
      return
    } else {
      this.setState({
        otpCodeError: "",
      })
    }
  }

  render() {
    const { loading, authenticated } = this.props
    const { withdrawHistory } = this.state

    if (this.state.errorMessage) {
      return <div className="withdraw-error-message">{this.state.errorMessage}</div>
    }

    if (this.state.withdrawalConfirmed) {
      return (
        <div className="withdraw-completed-container d-flex flex-column justify-content-center align-items-center">
          <div className="withdraw-completed mb-3">{this.state.withdrawalConfirmed}<br/>
          </div>
          <a className="withdraw-money-button btn btn-primary" href="/wallet">BACK</a>
        </div>
      );
    }

    return (
      <>
        <div className="withdraw-wrapper">
          <div className="withdraw-container">
          <div className="whatsNew">
              <p><Link to="/faq?question=withdrawal"><img src={'/img/info.svg'} alt=""></img>  How long until my withdrawal is processed?</Link></p>
            </div>
            <div className="withdraw-selector-block">
              {this.state.availableCoins.map(currency => (
                <div
                  className={`withdraw-currency-block ${this.state.selectedCurrencyId === currency.id ? "selected" : ""}`}
                  onClick={() => this.setCurrency(currency.id)}
                >
                  <div className="withdraw-currency-logo-container">
                    <img
                      alt="btc"
                      className="withdraw-currency-logo"
                      src={currency.imageUrl}
                    />
                  </div>
                  <div className="withdraw-currency-name">{`${currency.coin}(${currency.network})`}</div>
                </div>
              ))}
            </div>
            {this.state.coinError
              ? <div className="withdraw-coin-error-message">{this.state.coinError}</div>
              : <div className="withdraw-control-block">
                {this.state.selectedCurrencyId
                  ? (
                    <>
                      <div className="withdrawContent">
                        <div
                          className="withdrawal-fee"
                        >
                          <div className="withdrawImg"> <img src="./img/available.svg" alt=""/>Available for Withdrawal</div>
                          <div className="ml-4 primary-color">
                            {this.state.availableForWithdrawal
                              ? `$${parseFloat(this.state.availableForWithdrawal, 10).toFixed(8)}`
                              : "$0.00"
                            }
                          </div>
                        </div>
                        <div
                          className="withdrawal-fee"
                        >
                          <div className="withdrawImg"> <img src="./img/fee.svg"></img>  Withdrawal Fee</div>
                          <div className="ml-4 primary-color">
                            {this.state.withdrawalFee
                              ? `$${parseFloat(this.state.withdrawalFee, 10).toFixed(8)}`
                              : "$0.00"
                            }
                          </div>
                        </div>
                        <div className="form-group">
                          <label>Transfer amount</label>
                          <input
                            type="text"
                            className="withdraw-input form-control"
                            onChange={(e) => this.setWithdrawalAmount(e)}
                            placeholder="TRANSFER AMOUNT"
                            value={this.state.desiredAmount}
                            required
                          />
                        </div>
                        {this.state.withdrawalWarning
                          ? (<div className="withwraw-amount-warning">{this.state.withdrawalWarning}</div>)
                          : null
                        }
                        <div
                          className="withdrawal-fee"
                        >
                          <div className="withdrawImg"> <img src="./img/withdraw.svg" alt=""/> Withdrawal Amount</div>
                          <div className="ml-4 primary-color">
                            {this.state.withdrawalAmount
                              ? `$${parseFloat(this.state.withdrawalAmount, 10).toFixed(8)}`
                              : "$0.00"
                            }
                          </div>
                        </div>
                        <div className="form-group">
                          <label>Transfer amount</label>
                          <input
                            type="text"
                            className="withdraw-input form-control"
                            onChange={(e) => this.setState({ wallet: e.target.value })}
                            placeholder="WALLET ADDRESS"
                            value={this.state.wallet}
                            required
                          />
                        </div>
                        {this.state.showOtpCode
                          ? (
                            <>
                              <div className="form-group">
                                <label>Transfer amount</label>
                                <input
                                  type="text"
                                  className="withdraw-input form-control"
                                  onChange={(e) => this.confirmOtpCode(e)}
                                  placeholder="OTP PASSWORD"
                                  value={this.state.otpCode}
                                />
                                <div className="withdraw-coin-white-message">To confirm your withdrawal, please enter 2fa code<br/>Trying to withdraw sign up bonuses will be REJECTED</div>
                              </div>
                            </>
                          )
                          : null
                        }
                        {this.state.otpCodeCompleted
                          ? <div className="withdraw-completed-message">2FA code confirmed, you may click on WITHDRAW MONEY to finalize your withdrawal.</div>
                          : null
                        }
                        {this.state.otpCodeError
                          ? <div className="withdraw-coin-error-message">{this.state.otpCodeError}</div>
                          : null
                        }
                        <button
                          className="withdraw-money-button btn btn-primary"
                          onClick={this.withdrawMoney}
                          type="submit"
                        > WITHDRAW MONEY</button>
                        {this.state.withdrawErrorMessage
                          ? <div className="withdraw-coin-error-message">{this.state.withdrawErrorMessage}</div>
                          : null
                        }
                      </div>

                      <div className="withdrawBrainImag">
                        <img src={"./img/brain.svg"}></img>
                      </div>
                    </>
                  )
                  : null
                }
              </div>
            }
            {withdrawHistory && withdrawHistory.length > 0
              ? (
                <div className="deposit-history-block mb-5">
                  <div className="bots-table-title mt-4">Withdraw History</div>
                  <table className="bots-table">
                    <thead>
                      <tr>
                        <th>ID</th>
                        <th>Date</th>
                        <th>Status</th>
                        <th>Coin (network)</th>
                        <th>Address</th>
                        <th>Amount</th>
                        <th>Balance before</th>
                        <th>Balance after</th>
                      </tr>
                    </thead>
                    <tbody>
                      {withdrawHistory.map(element => (
                        <tr>
                          <td>
                            <Tooltip
                              className="dashboard-bot-status-desktop"
                              placement="top"
                              title={element.id}
                            >
                              <span className="withdraw-element-id">{element.id}</span>
                            </Tooltip>
                          </td>
                          <td className="deposit-element-date">
                            {element.created_at
                              ? DateTime.fromISO(element.created_at).toFormat("yyyy-LL-dd HH:mm:ss")
                              : ""
                            }
                          </td>
                          <td>{element.status}</td>
                          <td className="withdraw-coin-network">{element.coin}</td>
                          <td className="withdraw-address">{element.address}</td>
                          <td>
                            {element.amount
                              ? `$${parseFloat(element.amount, 10).toFixed(8)}`
                              : "$0.00"
                            }
                          </td>
                          <td>
                            {element.balance_before
                              ? `$${parseFloat(element.balance_before, 10).toFixed(8)}`
                              : "$0.00"
                            }
                          </td>
                          <td>
                            {element.balance_after
                              ? `$${parseFloat(element.balance_after, 10).toFixed(8)}`
                              : "$0.00"
                            }
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </div>
              )
              : null
            }
          </div>
        </div>
      </>
    );
  }
}
