import styled from "styled-components";

const SimpleButton = styled.div`
  background-color: #007bff;
  border-radius: 0.25rem;
  color: #FFF;
  cursor: pointer;
  display: inline-block;
  font-family: Overpass, sans-serif;
  font-size: 1.5rem;
  font-weight: 600;
  line-height: 1.5;
  padding: 0.375rem 0.75rem;
  text-align: center;
  transition: background-color .15s;
  user-select: none;
  vertical-align: middle;
  width: 260px;
  -webkit-user-select: none;
  &:hover {
    background-color: #0069d9;
    color: #FFF;
    text-decoration: none;
  }
`;

export default SimpleButton
