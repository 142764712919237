import React from 'react';

export default class ChartDetails extends React.Component {



  render() {

    return (
      <div className="faq-container">
        <p><b>BTC fund or ETH fund means that Bitcoin or Ethereum has been held on a futures exchange as collateral(margin) and actively traded by Crypto Manager.</b></p><br />        

        <div className="faq-title">ADVANCED BTC FUND PERFORMANCE DETAILS </div>
        <div className="faq-text">

          <p>Detailed charts of positions, average entries and balances can be found <a href="/BTC_charts.html" target="_blank">here</a>.</p><br />
          
          <p>Full financial tearsheet: <br / ><br / >
          1) <a href="/tearsheet_BTC_fund_vs_BTC_USD.html" target="_blank">BTC fund benchmark against BTC</a><br />
          2) <a href="/tearsheet_BTC_fund_vs_SPY.html" target="_blank">BTC fund benchmark against SPY</a><br />
          3) <a href="/tearsheet_BTC_fund_vs_NDAQ.html" target="_blank">BTC fund benchmark against NASDAQ</a>
          </p>
          <br />

        </div>
               
        <div className="faq-title">ADVANCED ETH FUND PERFORMANCE DETAILS </div>
        <div className="faq-text">
         <p>Detailed charts of positions, average entries and balances can be found <a href="/ETH_charts.html" target="_blank">here</a>.</p><br />
         
          <p>Full financial tearsheet: <br / ><br / >
          1) <a href="/tearsheet_ETH_fund_vs_ETH_USD.html" target="_blank">ETH fund benchmark against ETH</a><br />
          2) <a href="/tearsheet_ETH_fund_vs_SPY.html" target="_blank">ETH fund benchmark against SPY</a><br />
          3) <a href="/tearsheet_ETH_fund_vs_NDAQ.html" target="_blank">ETH fund benchmark against NASDAQ</a>
          </p><br /><br />
        </div>
      </div>
    )
  }
}
