import React from 'react';

export default class PrivacyPolicy extends React.Component {
  render() {
    return (
      <div className='pp-container'>
        <div className='pp-title'>THE CRYPTO MANAGER Privacy Policy </div>
        <div className='pp-title'>Last revision: April 12, 2022 </div>
        <div className='pp-title'>I. Scope </div>
        <div className='pp-text text-privacy'>
          The Algo Trading Lab (hereinafter also referred to as “we” or “TATL”)
          is the provider responsible for this website and its sub-pages
          (hereinafter also referred to as “offer”). TATL uses this website to
          offer software development services. The following privacy statement
          explains the nature, the scope and the purposes of levying, processing
          and usage of personal data by TATL on our website and sub-pages.
        </div>
        <div className='pp-title'>II. The input of data by you </div>
        <div className='pp-text text-privacy'>
          <h4 className='title'> 1. Personal data and its usage</h4>
          Personal data is specific information about personal or factual
          circumstances of an identified or identifiable natural person. These
          include the name, the e-mail address or the phone number. As part of
          our offer, personal data is only levied, processed and used where
          applicable and as permitted by law, or you have given consent. The
          same applies to the disclosure of personal data.
          <h4 className='title'>2. Cookies</h4>
          Our offer causes the storage of cookies on your browser. Cookies are
          text files that help to facilitate our offer, by storing Login data or
          registering when and how frequently you visit our offer. The storing
          of cookies can be disabled completely in all major browsers.
          <h4 className='title'>3. Making contact</h4>
          Our offer enables you to establish contact to us. This is possible,
          for example by sending an email to us. The information you provide
          while contacting us is stored, in order to process the request and,
          where appropriate, the following correspondence.
          <h4 className='title'>4. Financial operations</h4>
          Our offer also contains areas where the possibility of financial
          operations is present. To protect ourselves from illegal activity, we
          store your IP-address while using our services.
          <h4 className='title'>5. Registration function</h4>
          You can register as a user of our offer on the relevant and pertaining
          website pages or as an investor. The data received will be used here
          for the use of our offer. The utilisation also takes place, to inform
          you about changes in the scope of our offer or technical changes via
          email.
        </div>
        <div className='pp-title'>III. Visiting our Website</div>
        <div className='pp-text text-privacy'>
          In addition to the entering of personal data, data is collected by us
          and third parties automatically during the utilisation of our offer.
          The use of our service includes interacting with third parties.
          <h4 className='title'>1. Access Data/Server Log Files</h4>
          Your visit to our site is stored in a log file (so-called server log
          files). To this end, we collect (or our internet provider) the
          following data on each access to our offer:
          <ul className='list-items'>
            <li>IP address of the accessing computer,</li>
            <li>Name of the requested file,</li>
            <li>Date and time of the request,</li>
            <li>Amount of data transmitted,</li>
            <li>Notification of successful request,</li>
            <li>
              Browser type and version along with the operating system you use,
            </li>
            <li>Referrer URL,</li>
            <li>Requesting Provider,</li>
            <li>Screen resolution.</li>
          </ul>
          The log data is used by us only for statistical analysis in the
          context of the operation of our offer. In the case of illegal use of
          our service, the log data also serves to inform a resolution in the
          eventuality of any violation of the law.
          <h4 className='title'>2. Google Analytics</h4>
          This website uses Google Analytics, a web analytics service provided
          by Google, Inc. (“Google”). Google Analytics uses “cookies”, which are
          text files placed on your computer, to help the website analyze how
          users use the site. The information generated by the cookie about your
          use of the website (including your IP address) will be transmitted to
          and stored by Google on servers in the United States. In case of
          activation of the IP anonymization, Google will truncate/anonymize the
          last octet of the IP address for Member States of the European Union
          as well as for other parties to the Agreement on the European Economic
          Area. Only in exceptional cases, the full IP address is sent to and
          shortened by Google servers in the USA. On behalf of the website
          provider Google will use this information for the purpose of
          evaluating your use of the website, compiling reports on website
          activity for website operators and providing other services relating
          to website activity and internet usage to the website provider. Google
          will not associate your IP address with any other data held by Google.
          You may refuse the use of cookies by selecting the appropriate
          settings on your browser. However, please note that if you do this,
          you may not be able to use the full functionality of this website.
          <h4 className='title'>4. Liability for Links</h4>
          Our offer contains Links to websites of external providers. The
          referred domains were checked at the time of the placement of the
          link. Their content are not and will not become part of our offer.
          Should we become aware that the content behind a link has changed and
          become illegal, we will remove the link.
        </div>
        <div className='pp-title'>IV. Right to Information</div>
        <div className='pp-text text-privacy'>
          To a reasonable extent, you can demand to be informed by us about
          which personal data we have stored concerning yourself, free of
          charge.
        </div>
        <div className='pp-title'>
          V. Changes, Corrections and Updates, Revocation
        </div>
        <div className='pp-text text-privacy'>
          You have the right to ask for the review and update of your data if
          you can not do so yourself. You can also have your personal data
          locked and deleted if they are not subject to statutory retention
          obligation. The data is also deleted, if you revoke your permission
          for storage, or the storage of the data is no longer possible due to
          legal reasons. Given consent for the future collection, processing and
          utilization of your personal data can be revoked by contacting us via
          email
          <a
            href='mailto:support@cryptomanager.ai'
            className='fittedinlinestyle'>
            support@cryptomanager.ai.
          </a>
        </div>
        <footer id='block-12' className='position-relative'>
          <div className='hero-background'>
            <img
              className='hero-background-left'
              src={'img/landing/hero_background_left.svg'}
              alt='graph'
            />
            <img
              className='hero-background-right'
              src={'img/landing/hero_background_right.svg'}
              alt='graph'
            />
          </div>

          <div className='header-container'>
            <div className='navbar-left-container'>
              <a className='navbar-logo-container' href='/'>
                <img
                  className='navbar-logo'
                  src={'/img/navbar/logo.png'}
                  alt='logo'
                />
              </a>
              <div className='footer-links-div'>
                <a
                  href='mailto:support@cryptomanager.ai'
                  className='footer-links'>
                  support@cryptomanager.ai
                </a>
              </div>
            </div>
          </div>
        </footer>
      </div>
    );
  }
}
