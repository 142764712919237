import * as React from "react";
import { Redirect, Link } from 'react-router-dom';
import urls from "../../urls";

export default class ProfileConfirm extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isLoggedIn: false,
      loading: true,
      sendingRequest: false,
      otpCode: "",
      otpConfirmed: false,
      otpDeviceConfirmed: false,
      otpError: "",
      qr: "",
      url: "",
    }

    this.checkAuth = this.checkAuth.bind(this)
    this.confirmAuth = this.confirmAuth.bind(this)
    this.drawQrCode = this.drawQrCode.bind(this)
    this.handleChange = this.handleChange.bind(this)
    this.fetchData = this.fetchData.bind(this)
  }


  async fetchData() {
    const token = localStorage.getItem("CRYPTO_MANAGER.token")

    let checkloginResponse = await fetch(urls["checklogin"], {
      headers: {
        "Authorization": "Bearer " + token
      },
      method: "GET",
    })

    let isLoggedIn = false
    let otpConfirmed = false
    let otpDeviceConfirmed = false

    if (checkloginResponse.ok) {
      const { user_id, otp_confirmed, otp_device_confirmed } = await checkloginResponse.json()

      if (user_id) {
        isLoggedIn = true
      }

      if (otp_confirmed) {
        otpConfirmed = true
      }

      if (otp_device_confirmed) {
        otpDeviceConfirmed = true
      }
    }

    this.setState({
      isLoggedIn,
      otpConfirmed,
      otpDeviceConfirmed,
    })

    if (isLoggedIn && otpDeviceConfirmed) {
      this.setState({
        loading: false
      })
      return
    }

    let createTotpResponse = await fetch(urls["totp-create"], {
      headers: {
        "Authorization": "Bearer " + token
      },
      method: "GET",
    })

    if (createTotpResponse.ok) {
      const { qr, secret, url } = await createTotpResponse.json()
      if (qr) {
        this.setState({
          qr
        })
      }
      if (url) {
        this.setState({
          qr
        })
      }
      if (secret) {
        this.setState({
          secret
        })
      }
    } else {
      console.log("error")
    }

    this.setState({
      loading: false
    })

    if(isLoggedIn && otpConfirmed && otpDeviceConfirmed) {
      return <Redirect to="/dashboard" />
    }
  }

  async componentDidMount() {
    this.fetchData()
  }

  async confirmAuth(e) {
    e.preventDefault();
    e.stopPropagation();
    const token = localStorage.getItem("CRYPTO_MANAGER.token")

    let otpCode = e.target.value
    if (!otpCode) {
       otpCode = this.state.otpCode
    }
    
    if (!otpCode) {
      this.setState({
        otpError: "Otp code is required"
      })
      return
    }

    this.setState({
      otpError: "",
      sendingRequest: true
    })

    this.setState({
      otpError: ""
    })
    
    let confirmAuthResponse = await fetch(urls["totp-login"] + otpCode + "/", {
      headers: {
        "Authorization": "Bearer " + token
      },
      method: "GET",
    })
    if (confirmAuthResponse.ok) {
      let resultToken = await confirmAuthResponse.json()
      if (resultToken && resultToken.token) {
        localStorage.removeItem("CRYPTO_MANAGER.token")
        localStorage.setItem("CRYPTO_MANAGER.token", resultToken.token)
        this.setState({
          otpConfirmed: true,
          otpDeviceConfirmed: true,
          sendingRequest: false
        })
      } else {
        this.setState({
          otpError: "Otp code is incorrect",
          sendingRequest: false
        })        
      }
    } else {
      this.setState({
        otpError: "Otp code is incorrect",
        sendingRequest: false
      })
    }

    this.props.checkLogin()
  }

  drawQrCode() {
    const { otpDeviceConfirmed, qr } = this.state

    if (!otpDeviceConfirmed && qr) {
      return(
        <img
          className="profile-confirm-qr-code"
          src={`data:image/png;base64, ${qr}`}
        />
      )
    } else {
      return null
    }
  }

  async checkAuth() {
    const token = localStorage.getItem("CRYPTO_MANAGER.token")

    let confirmAuthResponse = await fetch(urls["test"], {
      headers: {
        "Authorization": "Bearer " + token
      },
      method: "GET",
    })
  }

  async handleChange(e) {
    this.setState({ otpCode: e.target.value })
    if (e.target.value.length === 6) {
      setTimeout(() => {
        this.confirmAuth(e)
      }, 1000)
    }
  }

  render() {
    const {
      loading,
      isLoggedIn,
      otpConfirmed,
      otpDeviceConfirmed,
      qr
    } = this.state

    if (loading) {
      return <div className="profile-confirm-loading">Loading...</div>
    }

    if (!isLoggedIn) {
      return <Redirect to='/login'/>;
    }

    if (isLoggedIn && otpConfirmed && otpDeviceConfirmed) {
      return <Redirect to='/dashboard'/>;
    }

    if (!otpConfirmed) {
      return(
        <>
          
          <div className="profile-confirm-wrapper">
            <div className="otp-wrapper">            
              <div className="form-access profile-confirm-container block">                           
                <div className="profile-confirm-pre-description">
                    {!this.state.otpDeviceConfirmed
                  ? (
                    <>
                    <div className="qr-mobile-wrapper">
            {this.drawQrCode()}
            <div className="qr-mobile-secret"><span>Manual recovery key:<br/>{this.state.secret}</span></div>
          </div>
                    <p><Link to="/faq?question=2fa" target="_blank"><img src={'/img/info.svg'} alt=""></img>
                    What is 2fa authenticator and how to set it up?</Link></p>
                    Please setup 2nd factor authentication for your account. It is mandatory to have 2fa enabled at Crypto Manager. 
                    Make sure to write down your manual recovery key.<br/><br/>
                    In case you lose your phone and do not have this key written down you will permanently lose access to your Crypto Manager account.       
                    </>
                    )
                  : null
                    }
                </div>                
                <form className="profile-confirm-form" onSubmit={() => this.confirmAuth()}>
                  <span className="profile-confirm-description">Please enter 2fa code</span>
                  <div className="form-group">
                    <input
                      autoFocus
                      type={"number"}
                      className="profile-confirm-otp-code form-control"
                      onChange={this.handleChange}
                      placeholder="CODE"
                      value={this.state.otpCode}
                      required
                    />
                  </div>
                  {this.state.otpError
                    ? <div className="error">Something went wrong</div>
                    : null
                  }
                  {this.state.sendingRequest
                    ? <button
                        className="profile-confirm-button btn btn-primary loading"
                      >SENDING...</button>
                    : <button
                        className="profile-confirm-button btn btn-primary"
                        onClick={this.confirmAuth}
                        type="submit"
                      >SEND</button>
                  }
                </form>
              </div>
              <div className="profile-confirm-brain-container block">
                {!this.state.otpDeviceConfirmed && this.state.qr
                  ? (
                      <>
                        <img
                          className="profile-confirm-qr-code"
                          src={`data:image/png;base64, ${qr}`}
                        />
                        <div className="qr-desktop-secret">
                          <span>Manual recovery key:<br />{this.state.secret}</span>
                        </div>
                      </>
                    )
                  : (<img className="profile-confirm-brain" src={'img/landing/brain_2.svg'} alt="graph" />)
                }
              </div>
            </div>
          </div>
        </>
      )
    }
  }
}
