import * as React from 'react';
import { Link, Redirect } from 'react-router-dom';
import { DateTime } from "luxon";
import Tooltip from '@mui/material/Tooltip';
import urls from '../urls';
import describeError from "../utils/describeError";

const BOT_LIST_REFRESH_TIME = 60000

export default class Dashboard extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isLoggedIn: false,
      exchange: "",
      exchangesList: [],
      tradingPairsList: [],
      tradingPairs: [],
      apiKey: "",
      apiSecret: "",
      tradingPair: "",
      withdrawLoading: true,
      error: "",
      botList: [],
      loggedIn: false,
      showTooltipId: null
    };

    this.changeExchange = this.changeExchange.bind(this)
    this.changeTradingPair = this.changeTradingPair.bind(this)
    this.createBot = this.createBot.bind(this)
    this.encryptKey = this.encryptKey.bind(this)
    this.startBot = this.startBot.bind(this)
    this.stopBot = this.stopBot.bind(this)
  }

  async componentDidMount() {
    const token = localStorage.getItem("CRYPTO_MANAGER.token")

    let checkloginResponse = await fetch(urls["checklogin"], {
      headers: {
        "Authorization": "Bearer " + token
      },
      method: "GET",
    })

    if (checkloginResponse.ok) {
      const { balance, total_profit } = await checkloginResponse.json()
      this.setState({
        balance: balance || 0,
        totalProfit: total_profit || 0
      })
    }

    let botListJsonResponse = await fetch(urls["get-all-bots"], {
      headers: {
        "Authorization": "Bearer " + token
      },
      method: "GET",
    })

    if (botListJsonResponse.status === 401) {
      this.setState({ loggedIn: false })
    }

    const botListResponse = await botListJsonResponse.json()
    if (botListJsonResponse.ok) {
      const botList = botListResponse.results
      this.setState({ botList })
    } else {
      this.setState({
        error: describeError(botListResponse),
      })
      return
    }

    let tradingPairsListJsonResponse = await fetch(urls["get-all-trading-pairs"], {
      headers: {
        "Authorization": "Bearer " + token,
        "Content-Type": "application/json"
      },
      method: "GET",
    })

    let tradingPairsList
    const tradingPairsListResponse = await tradingPairsListJsonResponse.json()
    if (tradingPairsListJsonResponse.ok) {
      tradingPairsList = tradingPairsListResponse.results
      this.setState({ tradingPairsList: tradingPairsList })
    } else {
      this.setState({
        error: describeError(tradingPairsListResponse),
        withdrawLoading: false
      })
      return
    }

    const exchangesList = tradingPairsList.map(exchange => ({
      id: exchange.id,
      name: exchange.name,
    }))

    this.setState({
      exchangesList: exchangesList,
      withdrawLoading: false,
    })

    setTimeout(async function update() {
      const token = localStorage.getItem("CRYPTO_MANAGER.token")

      let botListJsonResponse = await fetch(urls["get-all-bots"], {
        headers: {
          "Authorization": "Bearer " + token
        },
        method: "GET",
      })

      const botListResponse = await botListJsonResponse.json()
      if (botListJsonResponse.ok) {
        const botList = botListResponse.results
        this.setState({ botList })
      } else {
        this.setState({
          error: describeError(botListResponse),
          withdrawLoading: false
        })
        return
      }

      setTimeout(update.bind(this), BOT_LIST_REFRESH_TIME);
    }.bind(this), BOT_LIST_REFRESH_TIME);
  }

  changeExchange(e) {
    this.setState({ exchange: e.target.value })

    const exchange = this.state.tradingPairsList.find(exchange => exchange.id == e.target.value)
      ? this.state.tradingPairsList.find(exchange => exchange.id == e.target.value)
      : []

    const tradingPairs = exchange && exchange.trading_pairs
      ? exchange.trading_pairs
      : []

    this.setState({
      tradingPairs: tradingPairs
    })
  }

  changeTradingPair(e) {
    this.setState({ tradingPair: e.target.value })
  }

  async encryptKey(key) {
    let encryptData = {
      "secret": key,
      "type": "encrypt",
    }

    const token = localStorage.getItem("CRYPTO_MANAGER.token")
    let encryptJsonResponse = await fetch(urls["encrypt"], {
      method: "POST",
      headers: {
        "Authorization": "Bearer " + token,
        "Content-Type": "application/json"
      },
      body: JSON.stringify(encryptData)
    })

    const encryptResponse = await encryptJsonResponse.json()
    let encryptedKey = ""
    let encryptError = ""
    if (encryptJsonResponse.ok) {
      encryptedKey = encryptResponse["encrypted_secret"]
    } else {
      encryptError = describeError(encryptResponse)
    }

    return {
      encryptedKey,
      encryptError
    }
  }

  async createBot() {
    let createBotError = ""
    const token = localStorage.getItem("CRYPTO_MANAGER.token")

    const {
      apiKey,
      apiSecret,
      botName,
      tradingPair,
    } = this.state

    if (!apiKey || !apiSecret || !tradingPair) {
      createBotError = "All fields are reqiured"
      this.setState({ createBotError })
      return
    }

    this.setState({
      createBotError: "",
      creatingBot: true
    })

    const encryptedApiSecret = await this.encryptKey(apiSecret)

    if (encryptedApiSecret && encryptedApiSecret.encryptError) {
      createBotError = encryptedApiSecret.encryptError
      this.setState({ createBotError })
      return
    } else if (!encryptedApiSecret || !encryptedApiSecret.encryptedKey) {
      createBotError = "Key encryption error"
      this.setState({ createBotError })
      return
    }

    let botData = {
      "trading_pair": tradingPair,
      "api_key": apiKey,
      "api_secret": encryptedApiSecret.encryptedKey,
      "is_active": false,
      "template_name": botName || "default"
    }

    let newBotJsonResponse = await fetch(urls["create-bot"], {
      method: "POST",
      headers: {
        "Authorization": "Bearer " + token,
        "Content-Type": "application/json"
      },
      body: JSON.stringify(botData)
    })

    if (newBotJsonResponse.ok) {
      this.setState({
        createBotError: "",
      })

      let botListJsonResponse = await fetch(urls["get-all-bots"], {
        headers: {
          "Authorization": "Bearer " + token
        },
        method: "GET",
      })

      const botListResponse = await botListJsonResponse.json()
      if (botListJsonResponse.ok) {
        const botList = botListResponse.results
        this.setState({
          botList,
          creatingBot: false
        })
      } else {
        this.setState({
          error: describeError(botListResponse),
          creatingBot: false
        })
      }
    } else {
      const newBotResponse = await newBotJsonResponse.json()
      createBotError = describeError(newBotResponse)
      this.setState({
        createBotError: createBotError,
        creatingBot: false
      })
    }
  }

  async startBot(botId) {
    const token = localStorage.getItem("CRYPTO_MANAGER.token")

    let botData = {
      "is_active": true,
    }

    let startedBotJsonResponse = await fetch(urls["manage-bot"] + botId + "/", {
      method: "PUT",
      headers: {
        "Authorization": "Bearer " + token,
        "Content-Type": "application/json"
      },
      body: JSON.stringify(botData)
    })

    if (startedBotJsonResponse.ok) {
      let botListJsonResponse = await fetch(urls["get-all-bots"], {
        headers: {
          "Authorization": "Bearer " + token
        },
        method: "GET",
      })

      if (botListJsonResponse.ok) {
        const botListResponse = await botListJsonResponse.json()
        const botList = botListResponse.results
        this.setState({
          botList,
          creatingBot: false
        })
      } else {
        this.setState({
          error: describeError(botListJsonResponse),
          creatingBot: false
        })
      }
    } else {
      const startedBotResponse = await startedBotJsonResponse.json()
      const testError = describeError(startedBotResponse)
      console.log("test error", testError, typeof (testError))
      this.setState({
        error: testError,
        creatingBot: false
      })
    }
  }

  async stopBot(botId) {
    const token = localStorage.getItem("CRYPTO_MANAGER.token")

    let botData = {
      "is_active": false,
    }

    let stoppedBotJsonResponse = await fetch(urls["manage-bot"] + botId + "/", {
      method: "PUT",
      headers: {
        "Authorization": "Bearer " + token,
        "Content-Type": "application/json"
      },
      body: JSON.stringify(botData)
    })

    if (stoppedBotJsonResponse.ok) {
      let botListJsonResponse = await fetch(urls["get-all-bots"], {
        headers: {
          "Authorization": "Bearer " + token
        },
        method: "GET",
      })

      if (botListJsonResponse.ok) {
        const botListResponse = await botListJsonResponse.json()
        const botList = botListResponse.results
        this.setState({
          botList,
          creatingBot: false
        })
      } else {
        this.setState({
          error: describeError(botListJsonResponse),
          creatingBot: false
        })
      }
    } else {
      const stoppedBotResponse = await stoppedBotJsonResponse.json()
      this.setState({
        error: describeError(stoppedBotResponse),
        creatingBot: false
      })
    }
  }

  async deleteBot(botId) {
    const token = localStorage.getItem("CRYPTO_MANAGER.token")

    const deleteBot = window.confirm("do you really want to delete this bot");

    if (!deleteBot) return

    let deletedBotJsonResponse = await fetch(urls["delete-bot"] + botId + "/", {
      method: "DELETE",
      headers: {
        "Authorization": "Bearer " + token,
        "Content-Type": "application/json"
      },
    })

    if (deletedBotJsonResponse.ok) {
      let botListJsonResponse = await fetch(urls["get-all-bots"], {
        headers: {
          "Authorization": "Bearer " + token
        },
        method: "GET",
      })

      if (botListJsonResponse.ok) {
        const botListResponse = await botListJsonResponse.json()
        const botList = botListResponse.results
        this.setState({
          botList,
          creatingBot: false
        })
      } else {
        this.setState({
          error: describeError(botListJsonResponse),
          creatingBot: false
        })
      }
    } else {
      const deletedBotResponse = await deletedBotJsonResponse.json()
      this.setState({
        error: describeError(deletedBotResponse),
        creatingBot: false
      })
    }
  }

  render() {
    const { loading, authenticated } = this.props

    return (
      <>
        <div className="dashboard-wrapper">
          <div className="summary-info-block">
            {typeof (this.state.balance) === "number"
              ? <div> <img alt="" src={'/img/balance.svg'} ></img>  Current Crypto Manager balance: <span> {this.state.balance} USD</span>   </div>
              : null
            }
            {typeof (this.state.totalProfit) === "number"
              ? <div><img alt="" src={'/img/lossProfit.svg'} ></img> Total profit/loss:  <span> {this.state.totalProfit} USD</span> </div>
              : null
            }
          </div>

          <div className="botConfigWrapper">
            <div className="bots-table-title">New bot</div>
            <div className="whatsNew">
              <p><Link to="/faq?question=api-keys"><img src={'/img/info.svg'} alt=""></img>  What are API keys and how to generate them?</Link></p>
              <p><font color="white">Our server ip for API key whitelisting: <b>146.59.92.158</b></font></p>
            </div>

            <div className="formConfig">

              <div className="form-group">
                <label>Bot Selection</label>
                <select
                  className="form-control text-capitalize"
                  onChange={this.changeExchange}
                  value={this.state.botName}
                >
                  <option disabled defaultValue value="">Select Bot</option>
                  <option value="default">Classic</option>
                  <option value="accumulator">Accumulator</option>
                  <option value="shorter">Shorter</option>
                </select>
              </div>


              <div className="form-group">
                <label>Exchange</label>
                <select
                  className="form-control text-capitalize"
                  onChange={this.changeExchange}
                  value={this.state.exchange}
                >
                  <option disabled defaultValue value="">Select Exchange</option>
                  {this.state.exchangesList.map(exchange => (
                    <option value={exchange.id} >{exchange.name}</option>
                  ))}
                </select>
              </div>

              <div className="form-group">
                <label>Api Key</label>
                <input
                  className="form-control"
                  onChange={(e) => this.setState({ apiKey: e.target.value })}
                  placeholder="Api Key"
                  value={this.state.apiKey}
                />
              </div>



              <div className="form-group">
                <label>Api Secret</label>
                <input
                  className="form-control"
                  onChange={(e) => this.setState({ apiSecret: e.target.value })}
                  placeholder="Api Secret"
                  value={this.state.apiSecret}
                />
              </div>


              <div className="form-group">
                <label>Market to work with</label>
                <select
                  className="form-control"
                  onChange={this.changeTradingPair}
                  value={this.state.tradingPair}
                >
                  <option disabled defaultValue value="">Select Currency</option>
                  {this.state.tradingPairs.map(pair => (
                    <option value={pair.id}>{pair.ticker}</option>
                  ))}
                </select>lue={this.state.apiSecret}
              </div>

              <div className="form-group">
                <button className="btn btn-info" onClick={this.createBot}>Save configuration</button>
              </div>

              
            </div>
          
            <div className="create-bot-error">{this.state.createBotError}</div>
          </div>

          <div className="bots-table-title">Configured bots</div>
          <div className="table-responsive">
            <table className="bots-table mt-4">
              <thead>
                <tr>
                  <th>Bot name</th>
                  <th>Trading Pair</th>
                  <th>Starting balance</th>
                  <th>Balance in USD</th>
                  <th>Launch date</th>
                  <th>Trades made</th>
                  <th>Volume traded</th>
                  <th>Profit since launch</th>
                  <th>Total profit</th>
                  <th>Status</th>
                  <th>Action</th>
                </tr>
              </thead>
              <tbody>
                {this.state.botList.map((bot) => {
                  return (
                    <tr key={bot.id}>
                      <td>{bot.name}</td>
                      <td>{bot.trading_pair.ticker}</td>
                      <td>{bot.bot_stats.starting_balance}</td>
                      <td>{bot.bot_stats.current_balance}</td>
                      <td>
                        {bot.created_at
                          ? DateTime.fromISO(bot.created_at).toFormat("yyyy-LL-dd HH:mm:ss")
                          : ""
                        }
                      </td>
                      <td>{bot.bot_stats.trades_made}</td>
                      <td>{bot.bot_stats.volume_traded}</td>
                      <td>{bot.bot_stats.current_profit}</td>
                      <td>{bot.bot_stats.total_profit}</td>
                      <td>
                        <Tooltip
                          className="dashboard-bot-status-desktop"
                          placement="top"
                          title={bot.status_description}
                        >
                          <span>{bot.status}</span>
                        </Tooltip>
                        <span className="dashboard-bot-status-mobile">{bot.status}</span>
                      </td>
                      <td>
                        <div className="bot-action-container">
                          {bot.is_active
                            ? <svg
                              className="bot-control-btn"
                              onClick={() => this.stopBot(bot.id)}
                              viewBox="0 0 24 24"
                            >
                              <path fill="currentColor" d="M12,2A10,10 0 0,0 2,12A10,10 0 0,0 12,22A10,10 0 0,0 22,12A10,10 0 0,0 12,2M9,9H15V15H9" />
                            </svg>
                            : <svg
                              className="bot-control-btn"
                              onClick={() => this.startBot(bot.id)}
                              viewBox="0 0 24 24"
                            >
                              <path fill="currentColor" d="M10,16.5V7.5L16,12M12,2A10,10 0 0,0 2,12A10,10 0 0,0 12,22A10,10 0 0,0 22,12A10,10 0 0,0 12,2Z" />
                            </svg>
                          }

                          <svg
                            className="bot-control-btn delete ml-3"
                            onClick={() => this.deleteBot(bot.id)}
                            viewBox="0 0 24 24"
                          >
                            <path fill="currentColor" d="M19,4H15.5L14.5,3H9.5L8.5,4H5V6H19M6,19A2,2 0 0,0 8,21H16A2,2 0 0,0 18,19V7H6V19Z" />
                          </svg>
                        </div>
                      </td>
                    </tr>
                  )
                })
                }
              </tbody>
            </table>
          </div>
          <div className="create-bot-error">{this.state.error}</div>
          <a className="support-link" href="mailto:support@cryptomanager.ai">Support</a>
        </div>
      </>
    )
  }
}
