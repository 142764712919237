import React from 'react';
import Header from './Header';

export default function Layout({
  agreeDisclaimer,
  authenticated,
  children,
  confirmDisclaimer,
  hideDisclaimer,
  pathname,
  setUnauthenticated,
  showDislcaimer,
  toggleDisclaimer,
  viewDisclaimer,
}) {
  return (
    <>
      <Header
        agreeDisclaimer={agreeDisclaimer}
        authenticated={authenticated}
        confirmDisclaimer={confirmDisclaimer}
        hideDisclaimer={hideDisclaimer}
        pathname={pathname}
        setUnauthenticated={setUnauthenticated}
        showDislcaimer={showDislcaimer}
        toggleDisclaimer={toggleDisclaimer}
        viewDisclaimer={viewDisclaimer}
      />
      {children}
    </>
  );
}
