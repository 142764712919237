import React from 'react';
import { Redirect, Link } from 'react-router-dom';
import urls from '../../urls';

export default class ForgetPassword extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      password: "",
      loading: false,
      confirmPassword: "",
      errorMsg: "",
      passwordReset: false
    };

    this.setPassword = this.setPassword.bind(this);
  }

  async setPassword(e) {
    e.preventDefault();
    e.stopPropagation();

    this.setState({
      loading: true
    })

    let resetPasswordObject = {
      password: this.state.password,
      repeat_password: this.state.confirmPassword,
    }
    let splitArray = window.location.href.split("?")

    let token = splitArray.pop();
    let response = await fetch(
      urls["resetPassword"]
        .replace("restToken", token)
      , {
        method: "POST",
        headers: {
          "Content-Type": "application/json"
        },
        body: JSON.stringify(resetPasswordObject)
      })
    const result = await response.json();
    if (result.result === "success") {
      this.setState({ passwordReset: true })

    } else {
      let errorMsgs = ""
      if (result.non_field_errors) {
        errorMsgs = result.non_field_errors[0]
      } else {
        errorMsgs = result.repeat_password[0]
      }
      this.setState({ errorMsg: errorMsgs })
    }

    this.setState({
      loading: false
    })

  }

  render() {



    return (

      <div className="login-container vh-100 d-flex">
        <div className="form-access my-auto block">

          {!this.state.passwordReset &&
            <>
              <span>Rest Password</span>
              <h2 className="dont-have-account top">
                Don't have an account? <Link to="/signup">Sign up here</Link>
              </h2>
              <div className="form-group">
                <input
                  autoFocus
                  type="Password"
                  className="form-control"
                  onChange={(e) => this.setState({ password: e.target.value })}
                  placeholder="PASSWORD"
                  value={this.state.password}
                  required
                />
              </div>
              <div className="form-group">
                <input
                  type="password"
                  className="form-control"
                  onChange={(e) => this.setState({ confirmPassword: e.target.value })}
                  placeholder="CONFIRM PASSWORD"
                  value={this.state.confirmPassword}
                  required
                />
              </div>

              {this.state.errorMsg && <span className="error">{this.state.errorMsg}</span>}
              {this.state.loading
                ? <button className="login-submit-btn btn btn-primary loading">SET PASSWORD</button>
                : <button onClick={this.setPassword} className="login-submit-btn btn btn-primary">SET PASSWORD</button>
              }

              <h2 className="dont-have-account bottom">
                Don't have an account? <Link to="/signup">Sign up here</Link>
              </h2>
            </>
          }

          {this.state.passwordReset &&
            <>
              <h3 className="setPasswordMsg">Your password has been updated successfully. Please <Link to="/login">loggedIn</Link>  with new password. </h3>
            </>
          }
        </div>
        <div className="brain-container block">
          <img className="brain" src={'../img/landing/brain_v2.svg'} alt="graph" />
        </div>
      </div>

    );
  }
}
