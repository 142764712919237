import * as React from 'react';
import { Redirect } from 'react-router-dom';
import urls from '../urls';
import ReactApexChart from "react-apexcharts";
import { Link, Button, Element, Events, animateScroll as scroll, scrollSpy, scroller } from 'react-scroll'

export default class Landing extends React.Component {

  constructor(props) {
    super(props);



    this.state = {
      activeSlide: 0,
      activeDividersArray: [],
      agreeDisclaimer: false,
      numberOfSlides: 12,
      viewDisclaimer: false,
      chartLoader: false,
      statistic: {},
      activechart: "ohlcvBTC",
      btcEquityData: [],
      ethData: [],
      usdEquityWithUSD: [],
      USDWithETHEquity: [],
      options1: {},
      options2: {},
      options3: {},
      options4: {},

    }

    this.drawSidebar = this.drawSidebar.bind(this)
    this.selectSlide = this.selectSlide.bind(this)
    this.rotateWheel = this.rotateWheel.bind(this)
  }

  componentDidMount() {
    this.selectSlide(0);
    this.getStatistics();
    this.ohlcv("ohlcvBTC");
    this.rotateWheelToActiveDot();
    this.fetchAllChartsData();
  }



  async getStatistics() {
    let startedBotJsonResponse = await fetch(urls["statistics"], {
      method: "GET",
      headers: {
        "Content-Type": "application/json"
      },
    })

    if (startedBotJsonResponse.ok) {
      const result = await startedBotJsonResponse.json();
      this.setState({ statistic: result.results })

    } else {

    }
  }
  fetchAllChartsData = async () => {
    Promise.all([
      fetch(urls['ohlcvBTC']).then(value => value.json()),
      fetch(urls['ohlcvETH']).then(value => value.json()),
      fetch(urls['ohlcvBTCUSD']).then(value => value.json()),
      fetch(urls['ohlcvETHUSD']).then(value => value.json()),
    ]).then(([ohlcvBTC, ohlcvETH, ohlcvBTCUSD, ohlcvETHUSD]) => {
      this.setState({
        btcEquityData: [
          {
            name: 'candle',
            data: ohlcvBTC.data
          }
        ]
      })

      this.setState({
        ethData: [
          {
            name: 'candle',
            data: ohlcvETH.data
          }
        ]
      })

      this.setState({
        usdEquityWithUSD: [
          {
            name: 'candle',
            data: ohlcvBTCUSD.data
          }
        ]
      })

      this.setState({
        USDWithETHEquity: [
          {
            name: 'candle',
            data: ohlcvETHUSD.data
          }
        ]
      })
    }).catch((err) => {
      console.log(err);
    });



    this.setState({
      options1: {
        chart: {
          height: 400,
          type: 'candlestick',

          toolbar: {
            show: false,
          },
        },
        annotations: {
          xaxis: [
            {
              x: 'Oct 06 14:00',
              borderColor: '#fff',
              label: {
                borderColor: '#fff',
                style: {
                  fontSize: '32px',
                  color: '#fff',
                  background: '#fff'
                },
                orientation: 'horizontal',
                offsetY: 7,
                text: 'Annotation Test'
              },

            },

          ]
        },
        tooltip: {
          enabled: true,
        },
        xaxis: {
          type: 'category',
          tickAmount: 10,
          labels: {
            formatter: function (val) {
              var date = new Date(val);
              return date.getFullYear() + '-' + (date.getMonth() + 1) + '-' + date.getDate();

            },
            style: {
              colors: ["#fff"],
              fontSize: '13px',
              fontWeight: 400,
              cssClass: 'apexcharts-xaxis-label',
            },
          }
        },
        yaxis: {
          tooltip: {
            enabled: true,
          },
          labels: {
            formatter: function (val) {
              return val + " " + "BTC"
            },
            style: {
              colors: ["#fff"],
              fontSize: '18px',
              fontWeight: 400,
            },
          },
        }
      }
    })

    this.setState({
      options2: {
        chart: {
          height: 400,
          type: 'candlestick',

          toolbar: {
            show: false,
          },
        },
        annotations: {
          xaxis: [
            {
              x: 'Oct 06 14:00',
              borderColor: '#fff',
              label: {
                borderColor: '#fff',
                style: {
                  fontSize: '32px',
                  color: '#fff',
                  background: '#fff'
                },
                orientation: 'horizontal',
                offsetY: 7,
                text: 'Annotation Test'
              },

            },

          ]
        },
        tooltip: {
          enabled: true,
        },
        xaxis: {
          type: 'category',
          tickAmount: 10,
          labels: {
            formatter: function (val) {
              var date = new Date(val);
              return date.getFullYear() + '-' + (date.getMonth() + 1) + '-' + date.getDate();

            },
            style: {
              colors: ["#fff"],
              fontSize: '13px',
              fontWeight: 400,
              cssClass: 'apexcharts-xaxis-label',
            },
          }
        },
        yaxis: {
          tooltip: {
            enabled: true,
          },
          labels: {
            formatter: function (val) {
              return val + " " + "ETH"
            },
            style: {
              colors: ["#fff"],
              fontSize: '18px',
              fontWeight: 400,
            },
          },
        }
      }
    })

    this.setState({
      options3: {
        chart: {
          height: 400,
          type: 'candlestick',

          toolbar: {
            show: false,
          },
        },
        annotations: {
          xaxis: [
            {
              x: 'Oct 06 14:00',
              borderColor: '#fff',
              label: {
                borderColor: '#fff',
                style: {
                  fontSize: '32px',
                  color: '#fff',
                  background: '#fff'
                },
                orientation: 'horizontal',
                offsetY: 7,
                text: 'Annotation Test'
              },

            },

          ]
        },
        tooltip: {
          enabled: true,
        },
        xaxis: {
          type: 'category',
          tickAmount: 10,
          labels: {
            formatter: function (val) {
              var date = new Date(val);
              return date.getFullYear() + '-' + (date.getMonth() + 1) + '-' + date.getDate();

            },
            style: {
              colors: ["#fff"],
              fontSize: '13px',
              fontWeight: 400,
              cssClass: 'apexcharts-xaxis-label',
            },
          }
        },
        yaxis: {
          tooltip: {
            enabled: true,
          },
          labels: {
            formatter: function (val) {
              return val + " " + "USD"
            },
            style: {
              colors: ["#fff"],
              fontSize: '18px',
              fontWeight: 400,
            },
          },
        }
      }
    })

    this.setState({
      options4: {
        chart: {
          height: 400,
          type: 'candlestick',

          toolbar: {
            show: false,
          },
        },
        annotations: {
          xaxis: [
            {
              x: 'Oct 06 14:00',
              borderColor: '#fff',
              label: {
                borderColor: '#fff',
                style: {
                  fontSize: '32px',
                  color: '#fff',
                  background: '#fff'
                },
                orientation: 'horizontal',
                offsetY: 7,
                text: 'Annotation Test'
              },

            },

          ]
        },
        tooltip: {
          enabled: true,
        },
        xaxis: {
          type: 'category',
          tickAmount: 10,
          labels: {
            formatter: function (val) {
              var date = new Date(val);
              return date.getFullYear() + '-' + (date.getMonth() + 1) + '-' + date.getDate();

            },
            style: {
              colors: ["#fff"],
              fontSize: '13px',
              fontWeight: 400,
              cssClass: 'apexcharts-xaxis-label',
            },
          }
        },
        yaxis: {
          tooltip: {
            enabled: true,
          },
          labels: {
            formatter: function (val) {
              return val + " " + "USD"
            },
            style: {
              colors: ["#fff"],
              fontSize: '18px',
              fontWeight: 400,
            },
          },
        }
      }
    })




    // let chartbtc = await fetch(urls['ohlcvBTC'], {
    //   method: "GET",
    //   headers: {
    //     "Content-Type": "application/json"
    //   },
    // })

    // if (chartbtc.ok) {
    //   const result = await chartbtc.json();
    //   console.log("-->", result.data)
    //   this.setState({
    //     btcEquityData: [
    //       {
    //         name: 'candle',
    //         data: result.data
    //       }
    //     ]
    //   })

    // }
  }


  async ohlcv(url) {
    this.setState({ chartLoader: true });
    this.setState({ activechart: url });
    setTimeout(() => {
      this.setState({ chartLoader: false });
    }, 3000);
  }


  selectSlide(index) {
    let firstDivider = index - 1 < 0 ? null : index - 1
    let lastDivider = index < 0 ? 0 : index

    this.setState({
      activeSlide: index,
      activeDividersArray: [firstDivider, lastDivider]
    })

    this.drawSidebar()
  }

  drawSidebar() {
    const {
      activeDividersArray,
      activeSlide,
      numberOfSlides
    } = this.state

    let result = []
    for (let i = 0; i < numberOfSlides; i++) {
      result = result.concat(
        <Link
          className={`landing-sidebar-element ${activeSlide === i ? 'active' : ''}`} onClick={() => this.selectSlide(i)}
          duration={500}
          smooth={true}
          activeClass="active"
          to={`block-${i}`}
        >&nbsp;</Link>
      )
      if (i !== numberOfSlides - 1) {
        result = result.concat(<div className={`landing-sidebar-divider ${activeDividersArray.some(item => item === i) ? 'active' : ''}`}>&nbsp;</div>)
      }
    }

    return <div className="landing-sidebar-container">{result}</div>
  }

  rotateWheelToActiveDot(e) {

    window.addEventListener('scroll', () => {

      var block0 = document.getElementById('block-0')
      var block0Space = block0.getBoundingClientRect().top;

      var block1 = document.getElementById('block-1')
      var block1Space = block1.getBoundingClientRect().top;


      var block2 = document.getElementById('block-2')
      var block2Space = block2.getBoundingClientRect().top;


      var block3 = document.getElementById('block-3')
      var block3Space = block3.getBoundingClientRect().top;


      var block4 = document.getElementById('block-4')
      var block4Space = block4.getBoundingClientRect().top;


      var block5 = document.getElementById('block-5')
      var block5Space = block5.getBoundingClientRect().top;


      var block6 = document.getElementById('block-6')
      var block6Space = block6.getBoundingClientRect().top;


      var block7 = document.getElementById('block-7')
      var block7Space = block7.getBoundingClientRect().top;


      var block8 = document.getElementById('block-8')
      var block8Space = block8.getBoundingClientRect().top;


      var block9 = document.getElementById('block-9')
      var block9Space = block9.getBoundingClientRect().top;


      var block10 = document.getElementById('block-10')
      var block10Space = block10.getBoundingClientRect().top;

      var block11 = document.getElementById('block-11')
      var block11Space = block11.getBoundingClientRect().top;


      var block12 = document.getElementById('block-12')
      var block12Space = block12.getBoundingClientRect().top;

      if (block0Space == 0) {
        this.selectSlide(0);
      } else if (block1Space > block0Space && block2Space > 1) {
        this.selectSlide(1)
      } else if (block2Space > block1Space && block3Space > 1) {
        this.selectSlide(2)
      } else if (block3Space > block2Space && block4Space > 1) {
        this.selectSlide(3)
      } else if (block4Space > block3Space && block5Space > 1) {
        this.selectSlide(4)
      } else if (block5Space > block4Space && block6Space > 1) {
        this.selectSlide(5)
      } else if (block6Space > block5Space && block7Space > 1) {
        this.selectSlide(6)
      } else if (block7Space > block6Space && block8Space > 1) {
        this.selectSlide(7)
      } else if (block8Space > block7Space && block9Space > 1) {
        this.selectSlide(8)
      } else if (block9Space > block8Space && block10Space > 1) {
        this.selectSlide(9)
      } else if (block10Space > block9Space && block11Space > 1) {
        this.selectSlide(10)
      } else if (block11Space > block10Space && block12Space > 1) {
        this.selectSlide(11)
      } else if (block11Space > 1) {
        this.selectSlide(12)
      }
    });
  }

  rotateWheel(e) {

    e.preventDefault();
    e.stopPropagation();
    const {
      activeSlide,
      numberOfSlides
    } = this.state
    let slide;
    if (e.deltaY > 0) {
      slide = activeSlide + 1
    } else {
      slide = activeSlide - 1
    }
    if (slide < 0) {
      slide = 0
    }


    if (slide > numberOfSlides - 1) {
      slide = numberOfSlides - 1
    }


    scroller.scrollTo(`block-${slide}`, {
      duration: 100,
      smooth: true
    });

    setTimeout(() => {
      this.selectSlide(slide)
    }, 100);
  }

  render() {
    const { showDislcaimer } = this.props

    if (this.props.authenticated) {
      return <Redirect to="/dashboard" />
    }

    return (
      <>
        {this.drawSidebar()}
        <div className="wrapper">
          {/* <div className="wrapper"> */}
          <Element className="block-0 page-section" name="block-0">
            <div id="block-0" className="hero-wrapper">
              <div className="hero-container standart-padding">
                <div className="hero-background">
                  <img className="hero-background-left" src={'img/landing/hero_background_left.svg'} alt="graph" />
                  <img className="hero-background-right" src={'img/landing/hero_background_right.svg'} alt="graph" />
                  <img className="hero-background-brain" src={'img/landing/brain_v2.svg'} alt="graph" />
                </div>

                <div className="hero-title">Welcome to <span className="green">Crypto Manager</span></div>
                <div className="hero-description">The Ultimate AI-Driven Crypto Trading Solution<br />Supercharge your trading with our state-of-the-art artificial intelligence<br/>and expertly-crafted investment strategies, meticulously designed for success.</div>
                <div className="hero-button hero" onClick={() => showDislcaimer()}>
                 LAUNCH MY BOT!
                </div>
              </div>
            </div>
          </Element>
          <Element className="block-1 page-section" name="block-1">
            <div id="block-1" className="summary-container standart-padding simple-background">
              <div className="summary-top-container">
                <div className="summary-top-container-left-block">
                <span>
                <p>Crypto Manager allows users to enter their API keys and automatically trade their cryptocurrency <b>24/7, 365 days a year</b>.</p>
                <p>Trading happens in the same manner as a professional hedge fund would trade, which includes:</p>
                </span></div>
                <div className="summary-top-container-right-block">
                  <div className="summary-top-container-right-block-string">
                    <div className="summary-top-container-right-block-icon-container">
                      <svg className="summary-top-container-right-block-icon first" width="100%" height="100%" viewBox="0 0 37 36" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M2.71631 26.1095V34.8564C2.71631 35.1202 2.89213 35.296 3.15585 35.296H7.81504C8.07877 35.296 8.25459 35.1202 8.25459 34.8564V26.1095C8.25459 24.5711 7.02386 23.3403 5.48545 23.3403C3.94704 23.3403 2.71631 24.615 2.71631 26.1095Z" fill="#26DE81" />
                        <path d="M12.7372 35.2958H17.3963C17.6601 35.2958 17.8359 35.1199 17.8359 34.8562V22.5489C17.8359 21.0105 16.6052 19.7798 15.0667 19.7798C13.5283 19.7798 12.2976 21.0105 12.2976 22.5489V34.8562C12.2976 35.1199 12.4734 35.2958 12.7372 35.2958Z" fill="#26DE81" />
                        <path d="M21.8799 19.0323V34.856C21.8799 35.1197 22.0557 35.2955 22.3194 35.2955H26.9786C27.2423 35.2955 27.4182 35.1197 27.4182 34.856V19.0323C27.4182 17.4939 26.1874 16.2632 24.649 16.2632C23.1106 16.2632 21.8799 17.4939 21.8799 19.0323Z" fill="#26DE81" />
                        <path d="M34.2306 12.7026C32.6922 12.7026 31.4614 13.9334 31.4614 15.4718V34.8558C31.4614 35.1195 31.6372 35.2953 31.901 35.2953H36.5602C36.8239 35.2953 36.9997 35.1195 36.9997 34.8558V15.4718C36.9997 13.9773 35.725 12.7026 34.2306 12.7026Z" fill="#26DE81" />
                        <path d="M34.5828 6.06555C34.5828 5.80182 34.407 5.626 34.1433 5.626H22.6711V3.64805C22.6711 3.51618 22.5832 3.34036 22.4514 3.25246C22.3195 3.16455 22.1437 3.16455 22.0118 3.25246L17.1768 5.66996C17.0449 5.75787 16.9131 5.88973 16.9131 6.06555C16.9131 6.24137 17.001 6.37323 17.1768 6.46114L22.0558 8.87865C22.0997 8.9226 22.1876 8.9226 22.2316 8.9226C22.3195 8.9226 22.4074 8.9226 22.4514 8.87865C22.5832 8.79074 22.6711 8.65887 22.6711 8.48305V6.5051H34.1433C34.407 6.5051 34.5828 6.28532 34.5828 6.06555Z" fill="#26DE81" />
                        <path d="M3.33108 17.1863V17.45C3.33108 18.5049 4.21018 19.384 5.26509 19.384C6.32 19.384 7.19909 18.5049 7.19909 17.45V16.9665C9.17705 16.2192 10.4517 14.505 10.4517 12.4831C10.4517 9.49419 8.16609 8.21951 6.45186 7.51624C4.73763 6.81296 4.73763 6.54924 4.73763 6.37342C4.73763 5.97783 5.353 5.93387 5.61672 5.93387C6.62768 5.93387 7.24304 6.28551 7.55073 6.46133L8.42982 6.94483C8.56168 6.98878 8.69355 7.03274 8.78146 6.98878C8.91332 6.94483 9.00123 6.85692 9.04518 6.72506L10.188 3.73614C10.2759 3.51637 10.188 3.2966 10.0122 3.20869L9.39682 2.85705C8.78146 2.50541 8.12214 2.24169 7.37491 2.10982V1.934C7.37491 0.879092 6.49582 0 5.4409 0C4.38599 0 3.5069 0.879092 3.5069 1.934V2.37355C1.5729 3.07682 0.430079 4.70314 0.430079 6.6811C0.430079 9.45024 2.67176 10.681 4.60577 11.4282C6.10022 12.0436 6.10022 12.4392 6.10022 12.6589C6.10022 13.3182 5.13322 13.3622 4.9574 13.3622C4.16622 13.3622 3.33108 13.0985 2.71572 12.7029L1.79267 12.0436C1.66081 11.9557 1.52894 11.9557 1.39708 11.9996C1.26522 12.0436 1.17731 12.1315 1.13335 12.2633L0.0344872 15.2962C-0.053422 15.472 0.0344872 15.6918 0.210306 15.8237L0.737761 16.2193C1.39708 16.6588 2.32013 17.0104 3.33108 17.1863Z" fill="#26DE81" />
                      </svg>
                    </div>
                    <div className="summary-top-container-right-block-description">conservative dollar cost averaging</div>
                  </div>
                  <div className="summary-top-container-right-block-string">
                    <div className="summary-top-container-right-block-icon-container">
                      <svg className="summary-top-container-right-block-icon second" width="100%" height="100%" viewBox="0 0 52 22" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M25.9701 21.8269C32.2077 21.8269 37.2457 16.9765 37.2457 10.9712C37.2457 4.96599 32.2077 0.17334 25.9701 0.17334C19.7325 0.17334 14.7544 5.02373 14.7544 10.9712C14.7544 16.9187 19.7925 21.8269 25.9701 21.8269ZM25.2504 5.83213V5.37019C25.2504 5.02373 25.5502 4.73502 25.9101 4.73502C26.27 4.73502 26.5699 5.02373 26.5699 5.37019V5.83213C27.8893 5.94762 28.909 6.52505 29.4487 7.33344C29.8686 7.96862 29.3288 8.77701 28.6091 8.77701C28.2492 8.77701 27.8893 8.54604 27.7094 8.25733C27.5295 7.85313 27.1096 7.56442 26.5699 7.50667V10.1628L27.2296 10.2783C29.1489 10.6825 30.0485 11.6064 30.0485 13.1655C30.0485 14.9555 28.789 16.0526 26.5699 16.2836V16.5723C26.5699 16.9187 26.27 17.2075 25.9101 17.2075C25.5502 17.2075 25.2504 16.9187 25.2504 16.5723V16.2836C23.7509 16.1681 22.6714 15.5907 22.1316 14.7823C21.6517 14.1471 22.1915 13.281 22.9712 13.281H23.0912C23.4511 13.281 23.8109 13.5119 23.9908 13.8006C24.2308 14.2048 24.6506 14.4935 25.2504 14.609V11.8374L24.6506 11.7219C22.8513 11.3177 21.8917 10.3361 21.8917 8.83476C21.8917 7.16022 23.1512 6.0631 25.2504 5.83213Z" fill="#00FF85" />
                        <path d="M28.0093 13.3963C28.0093 12.7611 27.5295 12.2992 26.5698 12.126V14.6667C27.4695 14.6089 28.0093 14.0892 28.0093 13.3963Z" fill="#00FF85" />
                        <path d="M25.2506 9.8738V7.50635C24.5308 7.62183 24.051 8.08378 24.051 8.71895C24.051 9.23863 24.4709 9.70057 25.2506 9.8738Z" fill="#00FF85" />
                        <path d="M6.11765 20.0367L7.797 22V11.9528L12.2953 14.4934L6.11765 0L0 14.4934L4.49827 11.9528V22L6.11765 20.0367Z" fill="#00FF85" />
                        <path d="M47.5016 10.0472V0L45.8222 1.96325L44.2029 0V10.0472L39.7046 7.50656L45.8222 22L51.9999 7.50656L47.5016 10.0472Z" fill="#00FF85" />
                      </svg>
                    </div>
                    <div className="summary-top-container-right-block-description">advanced analytics of money movement in the network and market analysis</div>
                  </div>
                  <div className="summary-top-container-right-block-string">
                    <div className="summary-top-container-right-block-icon-container">
                      <svg className="summary-top-container-right-block-icon third" width="100%" height="100%" viewBox="0 0 40 28" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M38.9468 16.5793L0.968241 23.9897C0.547192 24.0739 0.252458 24.495 0.336668 24.9581C0.420878 25.3371 0.757717 25.6318 1.13666 25.6318C1.17877 25.6318 1.22087 25.6318 1.30508 25.6318L18.9891 22.1792L16.3786 25.9687C15.9997 26.516 16.3786 27.2318 17.0523 27.2318H23.2838C23.9154 27.2318 24.3364 26.516 23.9575 25.9687L21.0523 21.7582L39.3258 18.2214C39.7468 18.1372 40.0416 17.7161 39.9573 17.253C39.7889 16.7898 39.3679 16.4951 38.9468 16.5793Z" fill="#00FF85" />
                        <path d="M4.88433 11.5261C5.51591 11.5261 6.10538 11.484 6.65274 11.3998C7.03168 10.5998 7.36852 9.79984 7.36852 9.463C7.36852 8.91564 6.69484 8.66301 6.3159 9.21037C5.76854 9.92616 5.55801 8.87354 4.92644 8.87354C4.33697 8.87354 4.00013 9.88405 3.53698 9.21037C3.11593 8.62091 2.40015 8.95775 2.40015 9.4209C2.40015 9.88405 2.69488 10.6419 3.03172 11.3577C3.62119 11.484 4.25276 11.5261 4.88433 11.5261Z" fill="#00FF85" />
                        <path d="M4.88416 21.4632C7.57888 21.4632 9.76833 20.1158 9.76833 17.2527C9.76833 15.4422 8.21045 13.4632 6.82099 12.2422C6.23152 12.3264 5.55784 12.3685 4.88416 12.3685C4.21049 12.3685 3.57891 12.3264 2.94734 12.2422C1.55788 13.4211 0 15.4422 0 17.2527C0 20.1579 2.18945 21.4632 4.88416 21.4632ZM4.42101 14.5159V14.0106C4.42101 13.9264 4.50522 13.8843 4.54733 13.8843H5.221C5.30521 13.8843 5.34732 13.9685 5.34732 14.0106V14.5159C5.97889 14.6422 6.48415 15.1474 6.52625 15.8211C6.52625 15.9053 6.48415 15.9895 6.35784 15.9895H5.68416C5.59995 15.9895 5.55784 15.9474 5.55784 15.8632C5.51574 15.6527 5.30521 15.4422 5.05258 15.4422H4.63154C4.3368 15.4422 4.12628 15.6527 4.12628 15.9474C4.12628 16.2422 4.3368 16.4527 4.63154 16.4527H5.05258C5.93679 16.4527 6.61046 17.2106 6.52625 18.0948C6.44204 18.7263 5.93679 19.2316 5.34732 19.4V19.9053C5.34732 19.9895 5.26311 20.0316 5.221 20.0316H4.54733C4.46312 20.0316 4.42101 19.9474 4.42101 19.9053V19.4C3.78944 19.2737 3.28418 18.7684 3.24207 18.0948C3.24207 18.0106 3.28418 17.9263 3.41049 17.9263H4.08417C4.16838 17.9263 4.21049 17.9684 4.21049 18.0527C4.25259 18.2632 4.46312 18.4737 4.71574 18.4737H5.13679C5.43153 18.4737 5.64205 18.2632 5.64205 17.9684C5.64205 17.6737 5.43153 17.4632 5.13679 17.4632H4.79995C4.04207 17.4632 3.32628 16.9158 3.24207 16.1158C3.15787 15.3158 3.66312 14.6422 4.42101 14.5159Z" fill="#00FF85" />
                        <path d="M33.8523 3.35791C34.6523 3.35791 35.4102 3.3158 36.0839 3.2316C36.547 2.26318 36.9681 1.25267 36.9681 0.789513C36.9681 0.115835 36.126 -0.221004 35.6207 0.494779C34.9471 1.42109 34.6944 0.0737305 33.8944 0.0737305C33.1365 0.0737305 32.7576 1.33688 32.1681 0.494779C31.6208 -0.263108 30.7366 0.200045 30.7366 0.789513C30.7366 1.37898 31.1155 2.34739 31.5366 3.2316C32.2523 3.2737 33.0523 3.35791 33.8523 3.35791Z" fill="#00FF85" />
                        <path d="M33.8528 15.8209C37.2212 15.8209 40.0001 14.1367 40.0001 10.5578C40.0001 8.28414 38.0632 5.79995 36.2948 4.28418C35.537 4.36839 34.737 4.4526 33.8949 4.4526C33.0528 4.4526 32.2107 4.36839 31.4528 4.28418C29.6844 5.79995 27.7476 8.28414 27.7476 10.5578C27.7476 14.1367 30.4844 15.8209 33.8528 15.8209ZM33.2633 7.18942V6.59995C33.2633 6.51574 33.3475 6.43153 33.4317 6.43153H34.2738C34.358 6.43153 34.4422 6.51574 34.4422 6.59995V7.18942C35.2001 7.35783 35.7896 7.98941 35.8738 8.7473C35.8738 8.83151 35.7896 8.91571 35.7054 8.91571H34.8633C34.7791 8.91571 34.6949 8.87361 34.6949 8.7894C34.6528 8.49467 34.4001 8.28414 34.1054 8.28414H33.6001C33.2633 8.28414 32.9686 8.57888 32.9686 8.91571C32.9686 9.25255 33.2633 9.54729 33.6001 9.54729H34.1054C35.158 9.54729 36.0001 10.4736 35.8738 11.5262C35.7896 12.3262 35.2001 12.9157 34.4422 13.0841V13.7157C34.4422 13.7999 34.358 13.8841 34.2738 13.8841H33.4317C33.3475 13.8841 33.2633 13.7999 33.2633 13.7157V13.1262C32.5054 12.9578 31.9159 12.3262 31.8317 11.5683C31.8317 11.4841 31.9159 11.3999 32.0001 11.3999H32.8422C32.9265 11.3999 33.0107 11.442 33.0107 11.5262C33.0528 11.821 33.3054 12.0315 33.6001 12.0315H34.1054C34.4422 12.0315 34.737 11.7367 34.737 11.3999C34.737 11.0631 34.4422 10.7683 34.1054 10.7683H33.6843C32.758 10.7683 31.9159 10.0947 31.8317 9.16834C31.7475 8.15783 32.3791 7.35783 33.2633 7.18942Z" fill="#00FF85" />
                      </svg>
                    </div>
                    <div className="summary-top-container-right-block-description">maximum leverage of 2x</div>
                  </div>
                </div>
              </div>
              <div className="summary-bottom-container simple-background">
                <div className="summary-bottom-container-block">
                  <div className="summary-bottom-container-block-title">Funds under management</div>
                  <div className="summary-bottom-container-block-delimiter">&nbsp;</div>
                  <div className="summary-bottom-container-block-description">&#36; {this.state.statistic.total_current_balance}</div>
                </div>
                <div className="summary-bottom-container-block">
                  <div className="summary-bottom-container-block-title">Earnings last month</div>
                  <div className="summary-bottom-container-block-delimiter">&nbsp;</div>
                  <div className="summary-bottom-container-block-description">
                    <span>&#36; {this.state.statistic.profit_month_money} <span className="summary-bottom-container-block-or">or</span> <span className={this.state.statistic.profit_month_percent >= 0 ?"summary-bottom-container-block-percent" : "summary-bottom-container-block-percent-red"}>{this.state.statistic.profit_month_percent}  &#37;</span></span>
                  </div>
                </div>
                <div className="summary-bottom-container-block">
                  <div className="summary-bottom-container-block-title">Earnings since start</div>
                  <div className="summary-bottom-container-block-delimiter">&nbsp;</div>
                  <div className="summary-bottom-container-block-description">
                    <span>&#36; {this.state.statistic.profit_since_start_money} <span className="summary-bottom-container-block-or">or</span> <span className="summary-bottom-container-block-percent"> {this.state.statistic.profit_since_start_percent}  &#37;</span></span>
                  </div>                  
                </div>
              </div>
            </div>
          </Element>
          <Element className="block-2 page-section" name="block-2">
            <div id="block-2" className="trading-track-container standart-padding">
              <div className="hero-background">
                <img className="hero-background-left" src={'img/landing/hero_background_left.svg'} alt="graph" />
                <img className="hero-background-right" src={'img/landing/hero_background_right.svg'} alt="graph" />
              </div>
              <div className="title-font">BTC Trading track record</div>
              <div className="trading-track-image-container">
              <div class="table-container standart-padding simple-background">
                <table class="table">
                    <thead>
                        <tr>
                            <th>Month</th><th>Balance</th><th>Gain %</th><th>Max drawdown %</th><th>Max win %</th><th>Trade count</th><th>Average trade size</th><th>Total trade amount</th><th>Average buy price</th><th>Average sell price</th>
                        </tr>
                    </thead>
                    <tbody>
                    <tr>
            <td>2024-07</td>
            <td><span class="btc-symbol">₿</span>0.9617</td>
            <td class="green-text">3.63%</td>
            <td class="red-text">-16.03%</td>
            <td class="green-text">22.69%</td>
            <td>346</td>
            <td>172.83</td>
            <td>59800</td>
            <td>62066.26</td>
            <td>65772.13</td>
        </tr>
        <tr>
            <td>2024-06</td>
            <td><span class="btc-symbol">₿</span>0.9307</td>
            <td class="red-text">-1.26%</td>
            <td class="red-text">-5.86%</td>
            <td class="green-text">4.17%</td>
            <td>499</td>
            <td>122.24</td>
            <td>61000</td>
            <td>64034.83</td>
            <td>70485.49</td>
        </tr>
        <tr>
            <td>2024-05</td>
            <td><span class="btc-symbol">₿</span>0.9426</td>
            <td class="green-text">8.10%</td>
            <td class="red-text">-6.35%</td>
            <td class="green-text">12.85%</td>
            <td>523</td>
            <td>155.45</td>
            <td>81300</td>
            <td>61744.58</td>
            <td>67261.97</td>
        </tr>
        <tr>
            <td>2024-04</td>
            <td><span class="btc-symbol">₿</span>0.8706</td>
            <td class="red-text">-3.77%</td>
            <td class="red-text">-4.81%</td>
            <td class="green-text">2.75%</td>
            <td>380</td>
            <td>177.63</td>
            <td>67500</td>
            <td>64571.17</td>
            <td>68279.57</td>
        </tr>
        <tr>
            <td>2024-03</td>
            <td><span class="btc-symbol">₿</span>0.9036</td>
            <td class="green-text">6.38%</td>
            <td class="red-text">-3.31%</td>
            <td class="green-text">8.62%</td>
            <td>214</td>
            <td>265.42</td>
            <td>56800</td>
            <td>64946.04</td>
            <td>70349.70</td>
        </tr>
        <tr>
            <td>2024-02</td>
            <td><span class="btc-symbol">₿</span>0.8494</td>
            <td class="green-text">1.42%</td>
            <td class="red-text">-1.29%</td>
            <td class="green-text">2.87%</td>
            <td>204</td>
            <td>148.04</td>
            <td>30200</td>
            <td>N/A</td>
            <td>43964.99</td>
        </tr>
        <tr>
            <td>2024-01</td>
            <td><span class="btc-symbol">₿</span>0.8374</td>
            <td class="green-text">2.46%</td>
            <td class="red-text">-8.36%</td>
            <td class="green-text">12.15%</td>
            <td>435</td>
            <td>143.22</td>
            <td>62300</td>
            <td>41960.67</td>
            <td>43953.07</td>
        </tr>
        <tr>
            <td>2023-12</td>
            <td><span class="btc-symbol">₿</span>0.8173</td>
            <td class="green-text">1.28%</td>
            <td class="red-text">-1.18%</td>
            <td class="green-text">2.26%</td>
            <td>215</td>
            <td>145.58</td>
            <td>31300</td>
            <td>42194.89</td>
            <td>43382.25</td>
        </tr>
        <tr>
            <td>2023-11</td>
            <td><span class="btc-symbol">₿</span>0.8070</td>
            <td class="green-text">0.31%</td>
            <td class="red-text">-0.07%</td>
            <td class="green-text">0.36%</td>
            <td>63</td>
            <td>128.57</td>
            <td>8100</td>
            <td>34779.56</td>
            <td>36014.17</td>
        </tr>
          <tr>
            <td>2023-10</td>
            <td><span class="btc-symbol">₿</span>0.8045</td>
            <td class="green-text">28.59%</td>
            <td class="red-text">-7.92%</td>
            <td class="green-text">31.16%</td>
            <td>0</td>
            <td>nan</td>
            <td>nan</td>
            <td>nan</td>
            <td>nan</td>
        </tr> 
          <tr>
            <td>2023-09</td>
            <td><span class="btc-symbol">₿</span>0.6257</td>
            <td class="green-text">0.76%</td>
            <td class="red-text">-7.88%</td>
            <td class="green-text">8.62%</td>
            <td>0</td>
            <td>nan</td>
            <td>nan</td>
            <td>nan</td>
            <td>nan</td>
        </tr>   
        <tr>
            <td>2023-08</td>
            <td><span class="btc-symbol">₿</span>0.6185</td>
            <td class="red-text">-26.39%</td>
            <td class="red-text">-34.24%</td>
            <td class="green-text">28.84%</td>
            <td>0</td>
            <td>nan</td>
            <td>nan</td>
            <td>nan</td>
            <td>nan</td>
        </tr>   
        <tr>
            <td>2023-07</td>
            <td><span class="btc-symbol">₿</span>0.8399</td>
            <td class="red-text">-2.01%</td>
            <td class="red-text">-3.71%</td>
            <td class="green-text">1.97%</td>
            <td>470</td>
            <td>102.98</td>
            <td>48400</td>
            <td>29793.47</td>
            <td>30777.66</td>
        </tr>     
        <tr>
            <td>2023-06</td>
            <td><span class="btc-symbol">₿</span>0.8572</td>
            <td class="green-text">22.73%</td>
            <td class="red-text">-20.52%</td>
            <td class="green-text">39.16%</td>
            <td>0</td>
            <td>nan</td>
            <td>nan</td>
            <td>nan</td>
            <td>nan</td>
        </tr>     
          <tr>
            <td>2023-05</td>
            <td><span class="btc-symbol">₿</span>0.6985</td>
            <td class="red-text">-5.31%</td>
            <td class="red-text">-22.52%</td>
            <td class="green-text">18.38%</td>
            <td>50</td>
            <td>$ 42.00</td>
            <td>$ 2100</td>
            <td>28080.95</td>
            <td>nan</td>
        </tr>     
          <tr>
            <td>2023-04</td>
            <td><span class="btc-symbol">₿</span>0.7377</td>
            <td class="green-text">2.89%</td>
            <td class="red-text">-5.29%</td>
            <td class="green-text">8.15%</td>
            <td>357</td>
            <td>$ 64.98</td>
            <td>$ 23200</td>
            <td>28226.82</td>
            <td>29386.6</td>
        </tr>                        
        <tr>
            <td>2023-03</td>
            <td><span class="btc-symbol">₿</span>0.7170</td>
            <td class="green-text">15.03%</td>
            <td class="red-text">-14.73%</td>
            <td class="green-text">33.8%</td>
            <td>262</td>
            <td>$ 13.07</td>
            <td>$ 3425</td>
            <td>21940</td>
            <td>nan</td>
        </tr>
        <tr>
            <td>2023-02</td>
            <td><span class="btc-symbol">₿</span>0.7122</td>
            <td class="green-text">0.67%</td>
            <td class="red-text">-4.75%</td>
            <td class="green-text">8.21%</td>
            <td>154</td>
            <td>$ 13.31</td>
            <td>$ 2050</td>
            <td>22078.99</td>
            <td>nan</td>
        </tr>
        <tr>
            <td>2023-01</td>
            <td><span class="btc-symbol">₿</span>0.6060</td>
            <td class="green-text">17.53%</td>
            <td class="red-text">-1.91%</td>
            <td class="green-text">19.03%</td>
            <td>35</td>
            <td>$ 34.00</td>
            <td>$ 1190</td>
            <td>16636.88</td>
            <td>nan</td>
        </tr>
        <tr>
            <td>2022-12</td>
            <td><span class="btc-symbol">₿</span>0.6102</td>
            <td class="red-text">-0.7%</td>
            <td class="red-text">-1.69%</td>
            <td class="green-text">0.86%</td>
            <td>315</td>
            <td>$ 12.30</td>
            <td>$ 3875</td>
            <td>16749.49</td>
            <td>nan</td>
        </tr>
        <tr>
            <td>2022-11</td>
            <td><span class="btc-symbol">₿</span>0.5398</td>
            <td class="green-text">13.05%</td>
            <td class="red-text">-5.99%</td>
            <td class="green-text">19.07%</td>
            <td>582</td>
            <td>$ 21.44</td>
            <td>$ 12480</td>
            <td>16749.69</td>
            <td>20219.64</td>
        </tr>
        <tr>
            <td>2022-10</td>
            <td><span class="btc-symbol">₿</span>0.5333</td>
            <td class="green-text">1.22%</td>
            <td class="red-text">-1.17%</td>
            <td class="green-text">1.66%</td>
            <td>481</td>
            <td>$ 21.89</td>
            <td>$ 10530</td>
            <td>19160.93</td>
            <td>20181.32</td>
        </tr>
        <tr>
            <td>2022-09</td>
            <td><span class="btc-symbol">₿</span>0.5114</td>
            <td class="green-text">4.27%</td>
            <td class="red-text">-3.1%</td>
            <td class="green-text">7.99%</td>
            <td>627</td>
            <td>$ 21.48</td>
            <td>$ 13470</td>
            <td>19347.9</td>
            <td>20961.52</td>
        </tr>
        <tr>
            <td>2022-08</td>
            <td><span class="btc-symbol">₿</span>0.4667</td>
            <td class="green-text">9.58%</td>
            <td class="red-text">-6.57%</td>
            <td class="green-text">15.87%</td>
            <td>614</td>
            <td>$ 18.31</td>
            <td>$ 11240</td>
            <td>20962.9</td>
            <td>23809.09</td>
        </tr>
        <tr>
            <td>2022-07</td>
            <td><span class="btc-symbol">₿</span>0.4402</td>
            <td class="green-text">6.03%</td>
            <td class="red-text">-9.94%</td>
            <td class="green-text">16.71%</td>
            <td>534</td>
            <td>$ 19.64</td>
            <td>$ 10485</td>
            <td>20274.5</td>
            <td>22115.95</td>
        </tr>
        <tr>
            <td>2022-06</td>
            <td><span class="btc-symbol">₿</span>0.5457</td>
            <td class="red-text">-19.34%</td>
            <td class="red-text">-23.31%</td>
            <td class="green-text">21.46%</td>
            <td>507</td>
            <td>$ 45.87</td>
            <td>$ 23255</td>
            <td>22214.74</td>
            <td>30608.92</td>
        </tr>
        <tr>
            <td>2022-05</td>
            <td><span class="btc-symbol">₿</span>0.6699</td>
            <td class="red-text">-18.53%</td>
            <td class="red-text">-42.15%</td>
            <td class="green-text">35.9%</td>
            <td>202</td>
            <td>$ 69.83</td>
            <td>$ 14105</td>
            <td>37726.17</td>
            <td>30855.59</td>
        </tr>
        <tr>
            <td>2022-04</td>
            <td><span class="btc-symbol">₿</span>0.6876</td>
            <td class="red-text">-2.58%</td>
            <td class="red-text">-5.18%</td>
            <td class="green-text">3.6%</td>
            <td>572</td>
            <td>$ 33.21</td>
            <td>$ 18995</td>
            <td>40811.67</td>
            <td>46343.81</td>
        </tr>
        <tr>
            <td>2022-03</td>
            <td><span class="btc-symbol">₿</span>0.7200</td>
            <td class="red-text">-4.51%</td>
            <td class="red-text">-8.16%</td>
            <td class="green-text">6.36%</td>
            <td>563</td>
            <td>$ 231.48</td>
            <td>$ 130325</td>
            <td>41125.42</td>
            <td>42356.12</td>
        </tr>
        <tr>
            <td>2022-02</td>
            <td><span class="btc-symbol">₿</span>0.7625</td>
            <td class="red-text">-5.68%</td>
            <td class="red-text">-16.64%</td>
            <td class="green-text">10.91%</td>
            <td>541</td>
            <td>$ 194.38</td>
            <td>$ 105160</td>
            <td>40011.52</td>
            <td>41032.05</td>
        </tr>
        <tr>
            <td>2022-01</td>
            <td><span class="btc-symbol">₿</span>0.7325</td>
            <td class="green-text">4.09%</td>
            <td class="red-text">-4.54%</td>
            <td class="green-text">10.56%</td>
            <td>584</td>
            <td>$ 221.05</td>
            <td>$ 129095</td>
            <td>40869.17</td>
            <td>40826.52</td>
        </tr>
        <tr>
            <td>2021-12</td>
            <td><span class="btc-symbol">₿</span>0.6375</td>
            <td class="green-text">14.8%</td>
            <td class="red-text">-5.28%</td>
            <td class="green-text">16.76%</td>
            <td>628</td>
            <td>$ 208.48</td>
            <td>$ 130925</td>
            <td>49354.09</td>
            <td>49809.61</td>
        </tr>
        <tr>
            <td>2021-11</td>
            <td><span class="btc-symbol">₿</span>0.6080</td>
            <td class="green-text">4.51%</td>
            <td class="red-text">-2.23%</td>
            <td class="green-text">6.77%</td>
            <td>594</td>
            <td>$ 300.76</td>
            <td>$ 178650</td>
            <td>59941.91</td>
            <td>61105.48</td>
        </tr>
        <tr>
            <td>2021-10</td>
            <td><span class="btc-symbol">₿</span>0.6330</td>
            <td class="red-text">-3.9%</td>
            <td class="red-text">-6.84%</td>
            <td class="green-text">3.44%</td>
            <td>614</td>
            <td>$ 267.34</td>
            <td>$ 164145</td>
            <td>57973.06</td>
            <td>57125.57</td>
        </tr>
        <tr>
            <td>2021-09</td>
            <td><span class="btc-symbol">₿</span>0.5960</td>
            <td class="green-text">6.22%</td>
            <td class="red-text">-3.86%</td>
            <td class="green-text">6.74%</td>
            <td>576</td>
            <td>$ 252.76</td>
            <td>$ 145590</td>
            <td>45186.7</td>
            <td>46530.72</td>
        </tr>
        <tr>
            <td>2021-08</td>
            <td><span class="btc-symbol">₿</span>0.5440</td>
            <td class="green-text">9.6%</td>
            <td class="red-text">-1.36%</td>
            <td class="green-text">11.11%</td>
            <td>640</td>
            <td>$ 223.26</td>
            <td>$ 142885</td>
            <td>44762.33</td>
            <td>46005.06</td>
        </tr>
        <tr>
            <td>2021-07</td>
            <td><span class="btc-symbol">₿</span>0.5350</td>
            <td class="green-text">1.46%</td>
            <td class="red-text">-5.01%</td>
            <td class="green-text">7.27%</td>
            <td>622</td>
            <td>$ 171.93</td>
            <td>$ 106940</td>
            <td>33600.13</td>
            <td>34915.26</td>
        </tr>
        <tr>
            <td>2021-06</td>
            <td><span class="btc-symbol">₿</span>0.5155</td>
            <td class="green-text">3.81%</td>
            <td class="red-text">-5.45%</td>
            <td class="green-text">5.82%</td>
            <td>651</td>
            <td>$ 163.02</td>
            <td>$ 106125</td>
            <td>35316.24</td>
            <td>36184.37</td>
        </tr>
        <tr>
            <td>2021-05</td>
            <td><span class="btc-symbol">₿</span>0.4975</td>
            <td class="green-text">3.61%</td>
            <td class="red-text">-10.15%</td>
            <td class="green-text">12.35%</td>
            <td>636</td>
            <td>$ 179.05</td>
            <td>$ 113875</td>
            <td>46496.69</td>
            <td>47052.83</td>
        </tr>
        <tr>
            <td>2021-04</td>
            <td><span class="btc-symbol">₿</span>0.4825</td>
            <td class="green-text">3.29%</td>
            <td class="red-text">-3.44%</td>
            <td class="green-text">6.47%</td>
            <td>548</td>
            <td>$ 224.21</td>
            <td>$ 122865</td>
            <td>56289.11</td>
            <td>57554.48</td>
        </tr>
        <tr>
            <td>2021-03</td>
            <td><span class="btc-symbol">₿</span>0.4720</td>
            <td class="green-text">2.21%</td>
            <td class="red-text">-5.22%</td>
            <td class="green-text">5.21%</td>
            <td>622</td>
            <td>$ 232.07</td>
            <td>$ 144350</td>
            <td>54049.46</td>
            <td>54784.67</td>
        </tr>
        <tr>
            <td>2021-02</td>
            <td><span class="btc-symbol">₿</span>0.4705</td>
            <td class="green-text">0.26%</td>
            <td class="red-text">-8.09%</td>
            <td class="green-text">6.78%</td>
            <td>574</td>
            <td>$ 146.86</td>
            <td>$ 84300</td>
            <td>46012.96</td>
            <td>46310.09</td>
        </tr>
        <tr>
            <td>2021-01</td>
            <td><span class="btc-symbol">₿</span>0.4840</td>
            <td class="red-text">-2.87%</td>
            <td class="red-text">-11.9%</td>
            <td class="green-text">10.92%</td>
            <td>653</td>
            <td>$ 119.18</td>
            <td>$ 77825</td>
            <td>33994.75</td>
            <td>35142.59</td>
        </tr>
        <tr>
            <td>2020-12</td>
            <td><span class="btc-symbol">₿</span>0.5315</td>
            <td class="red-text">-8.99%</td>
            <td class="red-text">-12%</td>
            <td class="green-text">3.3%</td>
            <td>583</td>
            <td>$ 59.60</td>
            <td>$ 34745</td>
            <td>21016.09</td>
            <td>22455.07</td>
        </tr>
        <tr>
            <td>2020-11</td>
            <td><span class="btc-symbol">₿</span>0.5460</td>
            <td class="red-text">-2.25%</td>
            <td class="red-text">-4.28%</td>
            <td class="green-text">4.31%</td>
            <td>562</td>
            <td>$ 55.68</td>
            <td>$ 31290</td>
            <td>16329.27</td>
            <td>16801.55</td>
        </tr>
        <tr>
            <td>2020-10</td>
            <td><span class="btc-symbol">₿</span>0.5715</td>
            <td class="red-text">-4.57%</td>
            <td class="red-text">-8.1%</td>
            <td class="green-text">2.32%</td>
            <td>402</td>
            <td>$ 44.14</td>
            <td>$ 17745</td>
            <td>11992.86</td>
            <td>12256.56</td>
        </tr>
        <tr>
            <td>2020-09</td>
            <td><span class="btc-symbol">₿</span>0.5745</td>
            <td class="red-text">-0.39%</td>
            <td class="red-text">-3.6%</td>
            <td class="green-text">4.87%</td>
            <td>459</td>
            <td>$ 39.38</td>
            <td>$ 18075</td>
            <td>10611.05</td>
            <td>10691.93</td>
        </tr>
        <tr>
            <td>2020-08</td>
            <td><span class="btc-symbol">₿</span>0.5680</td>
            <td class="green-text">1.13%</td>
            <td class="red-text">-1.85%</td>
            <td class="green-text">3.38%</td>
            <td>453</td>
            <td>$ 43.29</td>
            <td>$ 19610</td>
            <td>11519.68</td>
            <td>11694.61</td>
        </tr>
        <tr>
            <td>2020-07</td>
            <td><span class="btc-symbol">₿</span>0.5775</td>
            <td class="red-text">-1.42%</td>
            <td class="red-text">-2.61%</td>
            <td class="green-text">1.45%</td>
            <td>283</td>
            <td>$ 36.61</td>
            <td>$ 10360</td>
            <td>9615.85</td>
            <td>10017.38</td>
        </tr>
        <tr>
            <td>2020-06</td>
            <td><span class="btc-symbol">₿</span>0.5655</td>
            <td class="green-text">2.11%</td>
            <td class="red-text">-2.92%</td>
            <td class="green-text">3.55%</td>
            <td>382</td>
            <td>$ 39.83</td>
            <td>$ 15215</td>
            <td>9365.91</td>
            <td>9567.68</td>
        </tr>
        <tr>
            <td>2020-05</td>
            <td><span class="btc-symbol">₿</span>0.5410</td>
            <td class="green-text">4.59%</td>
            <td class="red-text">-4.27%</td>
            <td class="green-text">7.3%</td>
            <td>592</td>
            <td>$ 36.42</td>
            <td>$ 21560</td>
            <td>9146.83</td>
            <td>9298.03</td>
        </tr>
        <tr>
            <td>2020-04</td>
            <td><span class="btc-symbol">₿</span>0.4820</td>
            <td class="green-text">12.2%</td>
            <td class="red-text">-2.86%</td>
            <td class="green-text">13.89%</td>
            <td>550</td>
            <td>$ 26.41</td>
            <td>$ 14525</td>
            <td>7000.51</td>
            <td>7282.33</td>
        </tr>
        <tr>
            <td>2020-03</td>
            <td><span class="btc-symbol">₿</span>0.4675</td>
            <td class="green-text">3.15%</td>
            <td class="red-text">-14.39%</td>
            <td class="green-text">19.22%</td>
            <td>600</td>
            <td>$ 24.37</td>
            <td>$ 14620</td>
            <td>6868.26</td>
            <td>6779.25</td>
        </tr>
        <tr>
            <td>2020-02</td>
            <td><span class="btc-symbol">₿</span>0.5000</td>
            <td class="red-text">-6.14%</td>
            <td class="red-text">-7.94%</td>
            <td class="green-text">3.13%</td>
            <td>498</td>
            <td>$ 27.63</td>
            <td>$ 13760</td>
            <td>9514.98</td>
            <td>9793.62</td>
        </tr>
                    </tbody>
                </table>
            </div>
            <div className='text-right'>
                  <a target='_blank' href='/advanced-fund-performance-details'>For more detailed information click here</a>
                </div>
              </div>
            </div>
          </Element>
          <Element className="block-3 page-section" name="block-3">
            <div id="block-3" className="what-is-it-tailored-for-container standart-padding simple-background">
              <div className="title-font">What is it tailored for?</div>
              <div className="description-font">
                <span><p>It is created for <b>passive investors</b>, who want to earn extra on their cryptocurrencies held on supported exchanges.</p>
                <p><b>Minimum recommended testing period is 6 months</b>, without moving the starting capital. This is not a get rich quick bot, which earns impressive profits and blows up (gets margin call and loses everything) the account every 3 months.</p>
                <p>While losing everything in the account is still a possibility, this is a much more stable and reliable trading system than current cryptocurrency market offers.</p>
                <p>It has been used for managing large capital (&#36;1 million+) everyday starting 2020 February.</p></span>
              </div>
            </div>
          </Element>
          <Element className="block-4 page-section" name="block-4">
            <div id="block-4" className="profit-container standart-padding">
              <div className="hero-background">
                <img className="hero-background-left" src={'img/landing/hero_background_left.svg'} alt="graph" />
                <img className="hero-background-right" src={'img/landing/hero_background_right.svg'} alt="graph" />
              </div>
              <div className="title-font">What profits can be expected?</div>
              <div className="description-font">
                <span><p>Profits highly vary based on market conditions. An average of <span className="green"><b>1-3&#37;/month</b></span> can be expected, a good month can generate <span className="green"><b>5-30&#37; profit</b></span> and a bad month can generate about <span className="red"><b>5-30&#37;</b></span> loss. Please check our <a target='_blank' href='/advanced-fund-performance-details'>financial tearsheets</a> for more detailed information.</p>
                <p>Crypto Manager will be most profitable in highly volatile market conditions, when market participants become emotional. It earns best in a sideways market or in a market in any direction, which has regular corrections.</p>
                <p>Bitcoin and especially Ethereum proved to provide great market conditions for past several years.</p></span>
              </div>
            </div>
          </Element>
          <Element className="block-5 page-section" name="block-5">
            <div id="block-5" className="sharing-container standart-padding simple-background">
              <div className="sharing-background">
                <img className="sharing-background-hand thin" src={'img/landing/hand.svg'} alt="graph" />
                <img className="sharing-background-hand wide" src={'img/landing/hand_2.svg'} alt="graph" />
              </div>
              <div className="title-font simple-background ">Why are we sharing this?</div>
              <div className="sharing-description description-font">
                <span><p>We charge <b>20&#37; success</b> fee from the profits generated by Crypto Manager. After you start making money with Crypto Manager, you have to deposit 20&#37; of the profits to Crypto Manager or the trading bot is automatically turned off. Offering this service to the public allows us to increase our capital, while keeping our strategy proprietary.</p></span>
              </div>
              <div
                className="hero-button"
                onClick={() => showDislcaimer()}
              >GET STARTED!</div>
            </div>
          </Element>
          <Element className="block-6 page-section" name="block-6">
            <div id="block-6" className="profit-shared-container standart-padding">
              <div className="hero-background">
                <img className="hero-background-left" src={'img/landing/hero_background_left.svg'} alt="graph" />
                <img className="hero-background-right" src={'img/landing/hero_background_right.svg'} alt="graph" />
              </div>
              <div className="title-font">How are the trading profits shared?</div>
              <div className="description-font">
                <span><p>When you register an account, you have a balance of &#36;20, which let&#39;s Crypto Manager to earn &#36;100 for you on your cryptocurrency exchange account. If the profit goes over this amount even for a short period of time, Crypto Manager stops trading and charges your balance for the 20&#37; profit share.</p>
                <p>When you decide that you want to use Crypto Manager long term, you have to prefund the account with 20&#37; of potential monthly profits. For example if you are putting &#36;10.000 for management, expected profit could be between &#36;0 up to &#36;1000, so you will need roughly &#36;250 in your Crypto Manager wallet.</p>
                <p>It is better to have a reserve of Crypto Manager balance, because profits might fluctuate and every time bot is stopped, new launch costs &#36;5. </p>
                <p>We keep a history of all Crypto Manager trades and calculate the profit when Crypto Manager is stopped and charge our 20&#37; share.</p>
                <p>You are free to withdraw your Crypto Manager balance at any time. Withdrawals are processed once a week on Mondays.</p></span>
              </div>
            </div>
          </Element>
          <Element className="block-7 page-section" name="block-7">
            <div id="block-7" className="start-trading-container standart-padding simple-background">
              <div className="title-font">What do i need to do to start trading?</div>
              <div className="description-font">
                <span><p>First, you will need to generate api keys with trading permissions on one of the supported exchanges. Please find <a className="green link" href="/faq?question=api-keys">instructions here</a>. </p>
                <p>We highly recommend to always use a sub account or a dedicated exchange account for Crypto Manager so the capital is not mixed with other trading activities and will not suddenly decrease or increase.</p>
                <p>Once you have deposited the desired amount for management to your exchange of either BTC, ETH or USDT and saved your api keys, you can pre-fund your Crypto Manager account for potential profit share by clicking on DEPOSIT.</p>
                <p>When all balances are settled, you can click on &#34;START&#34; button on your saved bot. Every time you click on start Crypto Manager you are charged &#36;5. In the backend a dedicated virtual private server is being deployed to run Crypto Manager for you.</p>
                <p>Crypto Manager will query your margin balance and based on that, set limits for trading. So if you have BTC worth &#36;10.000, it will short maximum &#36;10.000 worth of BTC and go long maximum &#36;10.000 worth of BTC. </p>
                <p>It will not go over these position limits, unless the capital changes or the price of the underlying asset changes. After that it will analyze the market and send a grid of limit buy/sell orders and keep repeating the process every hour. Below video shows a quick guide how it works.</p></span>
              </div>
            </div>
          </Element>
          <Element className="block-8 page-section" name="block-8">
            <div id="block-8" className="video-container">
              <div className="hero-background">
                <img className="hero-background-left" src={'img/landing/hero_background_left.svg'} alt="graph" />
                <img className="hero-background-right" src={'img/landing/hero_background_right.svg'} alt="graph" />
              </div>
              <div className="video-wrapper">
                <iframe
                  id="youtube-video"
                  width="100%"
                  height="100%"
                  src="https://www.youtube.com/embed/V8X3882QK1w"
                  title="YouTube video player"
                  frameBorder="0"
                  allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                  allowFullScreen
                  onWheel={(e) => this.rotateWheel(e)}
                ></iframe>
              </div>
            </div>
          </Element>
          <Element className="block-9 page-section" name="block-9">
            <div id="block-9" className="stop-trading-container standart-padding simple-background">
              <div className="title-font">How to stop trading?</div>
              <div className="description-font">
                <span><p>If you decide that you need part of the managed capital or want to stop trading completely. You will need to click on &#34;STOP&#34; button next to your bot in the dashboard. Once the button is clicked, any open orders left on the exchange will be cancelled and no more orders will be sent.</p>
                <p>Based on the trades that Crypto Manager has done so far it will calculate the profit that has been generated. Usually you will still have an open long or short position. Crypto Manager will assume it is being market closed and calculate profit based on current market price.</p>
                <p>However the position that Crypto Manager has accumulated will not be touched and you will have to close it manually or keep it. Click on the <a href="/faq?question=close-positions">link here</a> to find a video tutorial how you can stop everything.</p>
                <p>If you plan to withdraw funds from margin account, we recommend closing any margin positions, because decreasing capital may cause sudden liquidation.<br/></p></span>
              </div>
            </div>
          </Element>
          <Element className="block-10 page-section" name="block-10">
            <div id="block-10" className="strategy-container standart-padding">
              <div className="hero-background">
                <img className="hero-background-left" src={'img/landing/hero_background_left.svg'} alt="graph" />
                <img className="hero-background-right" src={'img/landing/hero_background_right.svg'} alt="graph" />
              </div>
              <div className="title-font">What is the exact strategy behind this?</div>
              <div className="description-font">
                <span><p>Every hour Crypto Manager analyzes the market conditions it is trading on and based on the results it sends a grid of limit buy and sell orders.  This is called Market Making and it basically delivers more liquidity to the market it is trading on.</p>
                <p>Sometimes the orders get filled, and sometimes they get cancelled and replaced by new orders after an hour.  Strategy doesn&#39;t get old, because it adapts to each day&#39;s market conditions.</p>
                <p>Eventually it slowly accumulates a long or short position, which usually has a good entry price.  As time passes by Crypto Manager slowly capitalizes on emotional market moves. It is best to test Crypto Manager with a small balance to see it running live and get a feeling how it works.</p>
                </span>
              </div>
              <div
                className="hero-button"
                onClick={() => showDislcaimer()}
              >LAUNCH MY BOT!</div>
            </div>
          </Element>
          <Element className="block-11 page-section" name="block-11">
            <div id="block-11" className="currencies-wrapper simple-background">
              <div className="currencies-container standart-padding">
                <div className="currencies-background">
                  <img className="currencies-background-brain" src={'img/landing/brain_only.svg'} alt="graph" />
                </div>
                <div className="currencies-block left">
                  <div className="currencies-title">
                    Supported cryptocurrencies
                  </div>
                  <div className="currencies-description">
                    <div className="currencies-icon-container first">
                      <a href="https://bitcoin.org/en/" target="_blank">
                      <img className="currencies-icon" src={'img/landing/currency_1.svg'} alt="graph" />
                      </a>
                    </div>
                    <div className="currencies-icon-container second">
                      <a href="https://ethereum.org/en/" target="_blank">                    
                      <img className="currencies-icon" src={'img/landing/currency_2.svg'} alt="graph" />
                      </a>
                    </div>
                    <div className="currencies-icon-container third">
                      <a href="https://tether.to/en/" target="_blank">
                      <img className="currencies-icon" src={'img/landing/currency_3.svg'} alt="graph" />
                      </a>
                    </div>
                  </div>
                </div>
                <div className="currencies-block right">
                  <div className="currencies-title">
                    Supported exchanges
                  </div>
                  <div className="exchanges-description">
                    <div className="exchanges-icon-container first">
                      <a href="https://www.deribit.com/?reg=16360.9101" target="_blank">
                      <img className="exchanges-icon" src={'img/landing/exchange_1.svg'} alt="Deribit exchange supported" />
                      </a>
                    </div>
                    <div className="exchanges-icon-container second">
                      <a href="https://accounts.binance.com/en/register?" target="_blank">
                      <img className="exchanges-icon" src={'img/landing/exchange_2.svg'} alt="Binance exchange supported" />
                      </a>
                    </div>
                    <div className="exchanges-icon-container third">
                      <a href="https://www.bitmex.com/register/2powaz" target="_blank">
                      <img className="exchanges-icon" src={'img/landing/exchange_3.svg'} alt="Bitmex exchange supported" />
                      </a>
                    </div>
                    <div className="exchanges-icon-container fourth">
                      <a href="https://www.kraken.com/sign-up" target="_blank">
                      <img className="exchanges-icon" src={'img/landing/exchange_4.svg'} alt="Kraken exchange supported" />
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </Element>
          <Element name="block-12">
            <footer id="block-12" className='position-relative'>
              <div className="hero-background">
                <img className="hero-background-left" src={'img/landing/hero_background_left.svg'} alt="graph" />
                <img className="hero-background-right" src={'img/landing/hero_background_right.svg'} alt="graph" />
              </div>
              <div className="footer-disclaimer"><span><p><b>DISCLAIMER</b>: While we have been using Crypto Manager with our own funds successfully for more than 2 years, it doesn&#39;t mean there is no risk. We do not take any responsibility for any losses incurred by using Crypto Manager, even if it would be our code fault.</p>
              <p>While we try to ensure maximum safety by keeping api keys safe, sending only post_only orders to avoid getting liquidated and never using leverage above 2x it still has risks. If you are using BTC as collateral and price starts dropping sharply used leverage may become more than 2x, if price drops more than 50&#37; your account will be liquidated, due to too low collateral.</p>
              <p>If an exchange provides incorrect price data Crypto Manager may send orders with incorrect prices or if an exchange provides incorrect balance, Crypto Manager may go over the 2x leverage and get liquidated.</p>
              <p>In the future we plan to create an insurance fund to cover the mentioned risks, but at the moment WE DO NOT GUARANTEE ANYTHING, YOU MAY LOSE ALL YOUR FUNDS AND WE WILL NOT COVER ANY LOSSES.</p></span></div>
              <div className='header-container'>
                <div className='navbar-left-container'>
                  <a className='navbar-logo-container' href='/'>
                    <img
                      className='navbar-logo'
                      src={'/img/navbar/logo.png'}
                      alt='logo'
                    />
                  </a>
                </div>

                <div className='navbar-sign-in-container'>
                  <a className='navbar-sign-in-element' href='/login'>
                    Sign In
                  </a>
                  <div className='navbar-sign-in-delimiter'>&nbsp;</div>
                  <a
                    className='navbar-sign-in-element'
                    // href="/signup"
                    onClick={() => showDislcaimer()}>
                    Sign Up
                  </a>
                  <div className='navbar-sign-in-delimiter'>&nbsp;</div>
                  <a
                    className='navbar-sign-in-element'
                    href='mailto:support@cryptomanager.ai'>
                    Support
                  </a>
                  <div className='navbar-sign-in-delimiter'>&nbsp;</div>
                  <a className='navbar-sign-in-element' href='/privacy-policy'>
                    Privacy
                  </a>
                </div>
              </div>
            </footer>
          </Element>
        </div>
      </>
    );
  }
}
