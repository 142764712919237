import React from 'react';
import { Link } from 'react-router-dom';

export default function notfound() {
  return (
    <>
      <div className="registration-completed-container registration-completed-background-wrapper-img">
        {/* <div className="registration-completed-background-container">
          <div className="registration-completed-background-wrapper">
            <img className="registration-completed-background-left" src={'img/landing/hero_background_left.svg'} alt="graph" />
            <img className="registration-completed-background-right" src={'img/landing/hero_background_right.svg'} alt="graph" />
          </div>
        </div> */}
        <div className="registration-completed-image-container">
          <div className="registration-completed-image-wrapper">
            <img className="registration-completed-image-checkbox" src={'img/checkbox.svg'} alt="graph" />
            <img className="registration-completed-image" src={'img/landing/circle.svg'} alt="graph" />
          </div>
          <div className="registration-completed-title">Thank you for registering, please check your email for confirmation link.</div>
        </div>
      </div>
    </>
  );
}
