import * as React from 'react';
import { useEffect } from 'react'
import Layout from '../components/Layout';
import { Switch, Route, Redirect } from 'react-router-dom';
import Exchange from '../pages/exchange';
import Faq from '../pages/faq';
import Markets from '../pages/markets';
import Profile from './profile';
import Wallet from './wallet';
import Deposit from './deposit';
import Settings from './settings';
import Landing from './landing';
import Login from './account/login';
import Signup from './account/signup';
import ProfileConfirm from './account/profile-confirm';
import AccountCreated from './account/account-created';
import ConfirmAccount from './account/confirm-account';
import ForgetPassword from './account/forgetPassword';
import ChartDetails from './chart-details';
import PrivacyPolicy from './privacy-policy';
import Reset from './reset';
import Dashboard from './dashboard';
import OtpVerify from './otp-verify';
import OtpNumber from './otp-number';
import Lock from './lock';
import TermsAndConditions from './terms-and-conditions';
import NewsDetails from './news-details';
import Notfound from './notfound';
import urls from '../urls';

const JWT_TOKEN_LIFETIME = 240000

const PrivateRoute = ({
  authenticated,
  component: Component,
  isLoggedIn,
  loading,
  otpConfirmed,
  otpDeviceConfirmed,
  ...props
}) => {
  if (loading) return

  if (!window || !window.location || !window.location.pathname) return

  if (!authenticated && window.location.pathname === "/deposit") {
    return <Redirect to="/landing" {...props} />
  } else if (!authenticated && window.location.pathname === "/wallet") {
    return <Redirect to="/landing" {...props} />
  } else if (!authenticated && window.location.pathname === "/dashboard") {
    return <Redirect to="/landing" {...props} />
  } else if (authenticated && window.location.pathname === "/landing") {
    return <Redirect to="/dashboard" {...props} />
  } else {
    return <Component {...props} />
  }
};

export default class Index extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      agreeDisclaimer: false,
      authenticated: false,
      isLoggedIn: false,
      loading: true,
      otpConfirmed: false,
      otpDeviceConfirmed: false,
      pathname: window && window.location && window.location.pathname,
      viewDisclaimer: false,
    };

    this.checkLogin = this.checkLogin.bind(this)
    this.confirmDisclaimer = this.confirmDisclaimer.bind(this)
    this.hideDisclaimer = this.hideDisclaimer.bind(this)
    this.showDislcaimer = this.showDislcaimer.bind(this)
    this.toggleDisclaimer = this.toggleDisclaimer.bind(this)
  }

  async checkLogin() {
    const token = localStorage.getItem("CRYPTO_MANAGER.token")
    let checkloginResponse = await fetch(urls["checklogin"], {
      headers: {
        "Authorization": "Bearer " + token
      },
      method: "GET",
    })

    let isLoggedIn
    let onetimeWithdraw
    let otpConfirmed
    let otpDeviceConfirmed
    if (checkloginResponse.ok) {
      const {
        user_id,
        onetime_withdraw,
        otp_confirmed,
        otp_device_confirmed
      } = await checkloginResponse.json()

      isLoggedIn = user_id ? true : false
      onetimeWithdraw = onetime_withdraw ? true : false
      otpConfirmed = otp_confirmed ? true : false
      otpDeviceConfirmed = otp_device_confirmed ? true : false
    }

    this.setState({
      authenticated: isLoggedIn && otpConfirmed && otpDeviceConfirmed,
      isLoggedIn,
      onetimeWithdraw,
      otpConfirmed,
      otpDeviceConfirmed,
      pathname: window && window.location && window.location.pathname,
    })
  }

  async componentDidMount() {
    window.scrollTo(0, 0)
    await this.checkLogin()
    const token = localStorage.getItem("CRYPTO_MANAGER.token")
    let response = await fetch(urls["refresh"], {
      method: "POST",
      headers: {
        "Authorization": "Bearer " + token,
        "Content-Type": "application/json"
      },
      body: JSON.stringify({ token })
    })

    if (response && response.ok) {
      const { token } = await response.json()
      localStorage.setItem("CRYPTO_MANAGER.token", token)
    }
  
    let timerId = setTimeout(async function tick() {
      const token = localStorage.getItem("CRYPTO_MANAGER.token")
      let response = await fetch(urls["refresh"], {
        method: "POST",
        headers: {
          "Authorization": "Bearer " + token,
          "Content-Type": "application/json"
        },
        body: JSON.stringify({ token })
      })
      if (response && response.ok) {
        const { token } = await response.json()
        localStorage.setItem("CRYPTO_MANAGER.token", token)
      }
      timerId = setTimeout(tick, JWT_TOKEN_LIFETIME); // (*)
    }, JWT_TOKEN_LIFETIME);

    this.setState({ loading: false })
  }

  toggleDisclaimer() {
    this.setState({
      agreeDisclaimer: !this.state.agreeDisclaimer
    })
  }

  hideDisclaimer() {
    this.setState({ viewDisclaimer: false })
  }

  showDislcaimer() {
    this.setState({ viewDisclaimer: true })
  }

  confirmDisclaimer() {
    if (!this.state.agreeDisclaimer) return
    window.location.href = "/signup"
  }

  render() {
    const {
      agreeDisclaimer,
      authenticated,
      loading,
      onetimeWithdraw,
      pathname,
      viewDisclaimer
    } = this.state

    if (loading) {
      return <div className="auth-loading">LOADING...</div>
    }

    return (
      <>
        <Layout
          agreeDisclaimer={agreeDisclaimer}
          authenticated={authenticated}
          confirmDisclaimer={this.confirmDisclaimer}
          hideDisclaimer={this.hideDisclaimer}
          pathname={pathname}
          setUnauthenticated={this.checkLogin}
          showDislcaimer={this.showDislcaimer}
          toggleDisclaimer={this.toggleDisclaimer}
          viewDisclaimer={viewDisclaimer}
        >
          <Switch>
            <Route exact path="/">
              <Redirect to='/landing' />
            </Route>
            <PrivateRoute
              agreeDisclaimer={agreeDisclaimer}
              authenticated={authenticated}
              component={Landing}
              confirmDisclaimer={this.confirmDisclaimer}
              hideDisclaimer={this.hideDisclaimer}
              loading={loading}
              exact path="/landing"
              setUnauthenticated={this.checkLogin}
              showDislcaimer={this.showDislcaimer}
              toggleDisclaimer={this.toggleDisclaimer}
              viewDisclaimer={viewDisclaimer}
            />
            <Route exact path="/markets">
              <Markets />
            </Route>
            <Route exact path="/forgot-password">
              <ForgetPassword />
            </Route>
            <Route exact path="/profile">
              <Profile />
            </Route>
            <PrivateRoute
              authenticated={authenticated}
              component={Wallet}
              loading={loading}
              onetimeWithdraw={onetimeWithdraw}
              exact path="/wallet"
            />
            <PrivateRoute
              authenticated={authenticated}
              component={Deposit}
              loading={loading}
              exact path="/deposit"
            />
            <Route exact path="/settings">
              <Settings />
            </Route>
            <Route exact path="/login">
              <Login
                authenticated={authenticated}
                checkLogin={this.checkLogin}
              />
            </Route>
            <Route exact path="/signup">
              <Signup />
            </Route>
            <Route exact path="/profile-confirm">
              <ProfileConfirm
                authenticated={authenticated}
                checkLogin={this.checkLogin}
              />
            </Route>
            <Route exact path="/account-created">
              <AccountCreated />
            </Route>
            <Route path="/confirm-account">
              <ConfirmAccount />
            </Route>
            <Route exact path="/reset">
              <Reset />
            </Route>
            <Route exact path="/otp-verify">
              <OtpVerify />
            </Route>
            <Route exact path="/otp-number">
              <OtpNumber />
            </Route>
            <Route exact path="/lock">
              <Lock />
            </Route>
            <Route exact path="/terms-and-conditions">
              <TermsAndConditions />
            </Route>
            <Route exact path="/news-details">
              <NewsDetails />
            </Route>
            <Route exact path="/faq">
              <Faq />
            </Route>
            <Route exact path="/advanced-fund-performance-details">
              <ChartDetails />
            </Route>
            <Route exact path="/privacy-policy">
              <PrivacyPolicy />
            </Route>
            <PrivateRoute
              authenticated={authenticated}
              component={Dashboard}
              loading={loading}
              exact path="/dashboard"
            />
            <Route path="*">
              <Notfound />
            </Route>
          </Switch>
        </Layout>
      </>
    );
  }
}
