import React, { Component } from 'react';
import { Redirect, Link } from 'react-router-dom';
import urls from '../../urls';
import describeError from "../../utils/describeError";

export default class SignUp extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      agree_terms_and_conditions: false,
      email: "",
      error: "",
      loading: false,
      password: "",
      createdUser: "",
      repeat_password: "",
    };

    this.signUp = this.signUp.bind(this);
  }

  async signUp(e) {
    e.preventDefault();
    e.stopPropagation();

    if (this.state.loading) return

    if (!this.state.email || !this.state.password || !this.state.repeat_password) return;
    if (this.state.password !== this.state.repeat_password) return;
    if (!this.state.agree_terms_and_conditions) return;

    this.setState({
      error: "",
      loading: true
    })

    let signUpData = {
      email: this.state.email,
      password: this.state.password,
      repeat_password: this.state.repeat_password
    }
    let signUpResponse = await fetch(urls["sign-up"], {
      method: "POST",
      headers: {
        "Content-Type": "application/json"
      },
      body: JSON.stringify(signUpData)
    })

    let errorMessage = ""
    const result = await signUpResponse.json()
    if (signUpResponse.ok) {
      this.setState({ createdUser: true })
    } else {
      errorMessage = describeError(result)
    }

    this.setState({
      error: errorMessage,
      loading: false
    })
  }

  render() {
    const { createdUser } = this.state

    if (createdUser) {
      return <Redirect to='/account-created'/>;
    }

    return (
      <>
        <div className="login-container vh-100 d-flex">
          <div className="form-access my-auto block">
            <form onSubmit={this.signUp}>
              <span>SIGN UP</span>
              <h2 className="dont-have-account top">
                Already have an account? <Link to="/login">Sign in</Link>
              </h2>
              <div className="form-group">
                <input
                  type="email"
                  className="form-control"
                  onChange={(e) => this.setState({ email: e.target.value })}
                  placeholder="EMAIL ADDRESS"
                  value={this.state.email}
                  required
                />
              </div>
              <div className="form-group">
                <input
                  type="password"
                  className="form-control"
                  onChange={(e) => this.setState({ password: e.target.value })}
                  placeholder="PASSWORD"
                  required
                />
              </div>
              <div className="form-group">
                <input
                  type="password"
                  className="form-control"
                  onChange={(e) => this.setState({ repeat_password: e.target.value })}
                  placeholder="CONFIRM PASSWORD"
                  required
                />
              </div>
              <div className="custom-control custom-checkbox">
                <input
                  type="checkbox"
                  className="custom-control-input"
                  id="form-checkbox"
                  onClick={() => this.setState({
                    agree_terms_and_conditions: !this.state.agree_terms_and_conditions
                  })}
                  required
                />
                <label className="custom-control-label" htmlFor="form-checkbox">
                  I agree to the{' '}
                  <Link to="/terms-and-conditions">Terms & Conditions</Link>
                </label>
              </div>
              {this.state.error
                ? <div className="error">{this.state.error}</div>
                : null
              }
              {this.state.loading
                ? <button className="login-submit-btn btn btn-primary loading">SIGN UP</button>
                : <button className="login-submit-btn btn btn-primary" type="submit">SIGN UP</button>
              }
            </form>
            <h2 className="dont-have-account bottom">
              Already have an account? <Link to="/login">Sign in</Link>
            </h2>
          </div>
          <div className="logo-wrapper block">
            <div className="logo-container">
              <img className="logo" src={'img/landing/circle.svg'} alt="graph" />
              <div className="logo-percentage">0.07%</div>
              <div className="logo-percentage-description">WORLD<br />DOMINATION</div>
            </div>
            <div className="logo-container-description">
              <span>Don't worry... <span className="green">|</span></span>
              <span>I am not taking over the world... <span className="green">|</span></span>
              <span><span className="green"><b>yet |</b></span></span>
            </div>
            <div className="logo-container-bottom-block">
              <span>
                <span>Need to make some</span>
                <img className="logo-container-bottom-icon" src={'img/landing/bitcoin.svg'} alt="graph" />
                <span>first</span>
              </span>
            </div>
          </div>
        </div>
      </>
    );
  }
}
