import * as React from "react";
import { Link, NavLink } from 'react-router-dom';

export default class Header extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      showDropdownMenu: false
    };

    this.logOut = this.logOut.bind(this);
    this.brainHover = this.brainHover.bind(this);
    this.logoTextHover = this.logoTextHover.bind(this);
    this.removeClasses = this.removeClasses.bind(this);
  }

  componentDidMount() {
    this.updateNavbarTop();
    window.addEventListener('resize', this.updateNavbarTop);
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.updateNavbarTop);
  }

  updateNavbarTop = () => {
    const headerHeight = document.querySelector('header').offsetHeight;
    const navbarLinksContainer = document.querySelector('.navbar-links-container');
    if (navbarLinksContainer) {
      navbarLinksContainer.style.top = `${headerHeight}px`;
    }
  }

  logOut() {
    localStorage.removeItem("CRYPTO_MANAGER.token");
    this.props.setUnauthenticated();
  }

  brainHover() {
    const element = document.getElementById("techPart");
    element.classList.add("showBrainPart");
    element.classList.remove("showTextPart");
  }

  logoTextHover() {
    const element = document.getElementById("techPart");
    element.classList.add("showTextPart");
    element.classList.remove("showBrainPart");
  }

  removeClasses() {
    const element = document.getElementById("techPart");
    element.classList.remove("showTextPart");
    element.classList.remove("showBrainPart");
  }

  render() {
    const {
      agreeDisclaimer,
      authenticated,
      confirmDisclaimer,
      hideDisclaimer,
      pathname,
      showDislcaimer,
      toggleDisclaimer,
      viewDisclaimer,
    } = this.props;

    if (!authenticated && (
      pathname === "/login" ||
      pathname === "/signup" ||
      pathname === "/confirm-account" ||
      pathname === "/profile-confirm" ||
      pathname === "/account-created"
    )) {
      return (
        <header>
          <div className="header-sign-in-container">
            <a
              className="navbar-logo-container"
              href="/"
            >
              <img className="navbar-logo" src={'/img/navbar/logo.png'} alt="logo" />
            </a>
          </div>
        </header>
      );
    }

    return (
      <>
        <header>
          <div className="header-container">
            <div className="navbar-left-container">
              {authenticated && (
                <div
                  className="hamburger"
                  onClick={() => this.setState({ showDropdownMenu: !this.state.showDropdownMenu })}
                  aria-expanded={this.state.showDropdownMenu}
                  aria-controls="navbar-links-container"
                >
                  <div className={`menu__btn ${this.state.showDropdownMenu ? "opened" : ""}`}>
                    <span></span>
                  </div>
                </div>
              )}

              <a
                className="navbar-logo-container"
                href="/"
              >
                <div className="logoSplit">
                  <img
                    className="logoBrain"
                    onMouseEnter={this.brainHover}
                    onMouseLeave={this.removeClasses}
                    src={'/img/logoBrain.svg'}
                    alt="logo"
                  />
                  <img
                    className="logoText ml-2"
                    onMouseEnter={this.logoTextHover}
                    onMouseLeave={this.removeClasses}
                    src={'/img/logoText.svg'}
                    alt="logo"
                  />
                </div>
                <div id="techPart" className="techWrapper">
                  <div className="techContent">
                    <h3>0.11%</h3>
                    <p>WORLD DOMINATION</p>
                  </div>
                </div>
              </a>
            </div>

            <div className="navbar-links-desktop-container">
              {authenticated && (
                <NavLink
                  className="navbar-link"
                  activeClassName="active"
                  onClick={() => this.setState({ showDropdownMenu: false })}
                  to="/dashboard"
                >
                  Dashboard
                </NavLink>
              )}
              {authenticated && (
                <NavLink
                  className="navbar-link"
                  onClick={() => this.setState({ showDropdownMenu: false })}
                  to="/deposit"
                >
                  Deposit
                </NavLink>
              )}
              {authenticated && (
                <NavLink
                  className="navbar-link"
                  onClick={() => this.setState({ showDropdownMenu: false })}
                  to="/wallet"
                >
                  Withdrawal
                </NavLink>
              )}
              {authenticated && (
                <a
                  className="navbar-link"
                  onClick={() => this.setState({ showDropdownMenu: false })}
                  href="mailto:support@cryptomanager.ai"
                >
                  Support
                </a>
              )}
              {authenticated && (
                <NavLink
                  className="navbar-link"
                  onClick={() => this.setState({ showDropdownMenu: false })}
                  to="/faq"
                >
                  FAQ
                </NavLink>
              )}
            </div>

            {!authenticated ? (
              <div className="navbar-sign-in-container">
                <a
                  className="navbar-sign-in-element"
                  href="/login"
                >
                  Sign In
                </a>
                <div className="navbar-sign-in-delimiter">&nbsp;</div>
                <a
                  className="navbar-sign-in-element"
                  onClick={() => showDislcaimer()}
                >
                  Sign Up
                </a>
              </div>
            ) : (
              <div
                className="navbar-sign-in-container"
                onClick={() => this.logOut()}
              >
                <div className="navbar-sign-in-element">Log Out</div>
              </div>
            )}
          </div>
        </header>

        <div
          id="navbar-links-container"
          className={`navbar-links-container ${this.state.showDropdownMenu ? "opened" : ""}`}
        >
          {authenticated && (
            <Link
              className="navbar-link"
              onClick={() => this.setState({ showDropdownMenu: false })}
              to="/dashboard"
            >
              Dashboard
            </Link>
          )}
          {authenticated && (
            <Link
              className="navbar-link"
              onClick={() => this.setState({ showDropdownMenu: false })}
              to="/deposit"
            >
              Deposit
            </Link>
          )}
          {authenticated && (
            <Link
              className="navbar-link"
              onClick={() => this.setState({ showDropdownMenu: false })}
              to="/wallet"
            >
              Withdrawal
            </Link>
          )}
          {authenticated && (
            <Link
              className="navbar-link"
              onClick={() => this.setState({ showDropdownMenu: false })}
              to="/support"
            >
              Support
            </Link>
          )}
          {authenticated && (
            <Link
              className="navbar-link"
              onClick={() => this.setState({ showDropdownMenu: false })}
              to="/faq"
            >
              FAQ
            </Link>
          )}
        </div>

        <div className={`header-background ${this.state.showDropdownMenu ? "opened" : ""}`}>&nbsp;</div>

        {viewDisclaimer && (
          <div className="disclaimer-wrapper">
            <div className="disclaimer-background"></div>
            <div className="disclaimer-container">
              <svg
                className="disclaimer-close-icon"
                onClick={() => hideDisclaimer()}
                viewBox="0 0 24 24"
              >
                <path
                  fill="currentColor"
                  d="M19,6.41L17.59,5L12,10.59L6.41,5L5,6.41L10.59,12L5,17.59L6.41,19L12,13.41L17.59,19L19,17.59L13.41,12L19,6.41Z"
                />
              </svg>
              <div>
                <div className="disclaimer-description">
                  <span className="mobile"><b>DISCLAIMER</b>: While we have been using Crypto Manager with our own funds successfully for more than 2 years, it does not mean there is no risk. We do not take any responsibility for any losses incurred by using Crypto Manager, even if it would be our code fault. While we try to ensure maximum safety by keeping api keys safe, sending only post_only orders to avoid getting liquidated and never using leverage above 2x it still has risks. WE DO NOT GUARANTEE ANYTHING, YOU MAY LOSE ALL YOUR FUNDS AND WE WILL NOT COVER ANY LOSSES. <a href="/faq?question=risks" target="_blank" className="read-more">Read more</a></span>
                  <span className="laptop"><b>DISCLAIMER</b>: While we have been using Crypto Manager with our own funds successfully for more than 2 years, it does not mean there is no risk. We do not take any responsibility for any losses incurred by using Crypto Manager, even if it would be our code fault. While we try to ensure maximum safety by keeping api keys safe, sending only post_only orders to avoid getting liquidated and never using leverage above 2x it still has risks. If you are using BTC as collateral and price starts dropping sharply used leverage may become more than 2x, if price drops more than 50% your account will be liquidated, due to too low collateral. If an exchange provides incorrect price data Crypto Manager may send orders with incorrect prices or if an exchange provides incorrect balance, Crypto Manager may go over the 2x leverage and get liquidated. In the future we plan to create an insurance fund to cover the mentioned risks, but at the moment WE DO NOT GUARANTEE ANYTHING, YOU MAY LOSE ALL YOUR FUNDS AND WE WILL NOT COVER ANY LOSSES.</span>
                </div>
                <div className="disclaimer-agree-container" onClick={() => toggleDisclaimer()}>
                  {agreeDisclaimer ? (
                    <svg className="disclaimer-agree-checkbox" viewBox="0 0 24 24">
                      <path
                        fill="currentColor"
                        d="M19,3H5A2,2 0 0,0 3,5V19A2,2 0 0,0 5,21H19A2,2 0 0,0 21,19V5A2,2 0 0,0 19,3M19,5V19H5V5H19M10,17L6,13L7.41,11.58L10,14.17L16.59,7.58L18,9"
                      />
                    </svg>
                  ) : (
                    <svg className="disclaimer-agree-checkbox" viewBox="0 0 24 24">
                      <path
                        fill="currentColor"
                        d="M19,3H5C3.89,3 3,3.89 3,5V19A2,2 0 0,0 5,21H19A2,2 0 0,0 21,19V5C21,3.89 20.1,3 19,3M19,5V19H5V5H19Z"
                      />
                    </svg>
                  )}
                  <span>I Agree</span>
                </div>
              </div>
              <div
                className="disclaimer-ok-btn"
                onClick={() => confirmDisclaimer()}
              >OK</div>
            </div>
          </div>
        )}
      </>
    );
  }
}
