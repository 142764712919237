import React from 'react';
import { Link } from 'react-router-dom';
import urls from '../urls.json';
export default class Reset extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      email: "",
      errorMsg:"",
      showSendEmailSection: false
    };
    this.sendEmailLink = this.sendEmailLink.bind(this);
  }
  
  async sendEmailLink() {
    let getEmail = this.state.email; 
    let emailData = {
      email: this.state.email,
    }

    let response = await fetch(urls["getForgetLink"], {
      headers: { 
        "Content-Type": "application/json"
      },
      method: "POST",
      body: JSON.stringify(emailData)
    })
 
 
    const result = await response.json()
    if (response.ok) {
        this.setState({showSendEmailSection:true})
    } else {
      let errorMsgs = ""
      if(result.non_field_errors){
        errorMsgs = result.non_field_errors[0]
      }else{
        errorMsgs = result.email[0]
      }
     this.setState({errorMsg:errorMsgs})
    }

  }


  render() {
    return (
    
    <>
      <div className="vh-100 d-flex justify-content-center creWrapper">
        <div className="form-access my-auto">
          <div className='m-auto'>
            {!this.state.showSendEmailSection &&             
            <>
            <span>Reset password</span>
            <input
              type="email"
              className="form-control"
              placeholder="Enter Your Email Address"
              onChange={(e) =>  this.setState({email:e.target.value})}
            />
            {this.state.errorMsg && <span className="error">{this.state.errorMsg}</span>}
            <button type="button" className="btn btn-primary" onClick={this.sendEmailLink}>
              Reset
            </button>
            <h2>
              Remember Password?
              <Link to="/login"> Sign in here</Link>
            </h2>
            </>
            }
             {this.state.showSendEmailSection &&  
             <>
              <h3>Password reset link has been sent to your email. Please click on the link and follow instructions to reset your password.</h3>
             </>
             }
            
          </div>
        </div>
      </div>
    </>
    );
  }
}
